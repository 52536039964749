import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Table, Divider, Card, Tag } from "antd";
import { StopTwoTone, CheckCircleTwoTone, EditTwoTone, DeleteTwoTone, PlusOutlined, ImportOutlined } from "@ant-design/icons";
// import { contactService } from 'services/ContactService';
import Importer from "./Importer";

export default function Leads({ data, isAdmin }) {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [runSearch, setSearch] = useState(0);
    const [items, setItems] = useState(data); // this might not be right
    const [layout, setLayout] = useState("grid");
    const pagination = useState({ current: 1, pageSize: 20, total: 0, position: ["topRight", "bottomRight"], showSizeChanger: true });
    const importer = useRef(null);

    const renderStatus = (param) => {
        switch (param) {
            case 1:
                return <Tag color="volcano">unsubscribed</Tag>;
            case 2:
                return <Tag color="#333333">global opt-out</Tag>;
            default:
                return <Tag color="green">subscribed</Tag>;
        }
    };

    const handleAdd = (evt) => {
        if (isAdmin) navigate(`/manage/lead/${data.id}`);
        else navigate("/app/leads/lead");
    };

    const handleEdit = (id) => {
        if (isAdmin) navigate(`/manage/lead/${data.id}/${id}`);
        else navigate(`/app/leads/lead/${id}`);
    };

    const handleDelete = (id) => {
        if (!window.confirm("Are you sure you want to remove this item?")) return;

        /*
            contactService.deleteItem(id)
            .then(data => {
                
                // splice out the deleted object
                var idx = data.findIndex(o => o.id === id);
                if(idx > -1) {
                    let list = [...data];
                    list.splice(idx, 1);
                    setItems(list);
                }

            })
            .catch(err => console.log(err))
            .finally(() => setLoading(false));
            */
    };

    if (layout === "grid") {
        return (
            <Card
                style={{ width: "100%" }}
                title="Client List"
                extra={
                    <span>
                        <Button type="primary" onClick={handleAdd}>
                            <PlusOutlined /> Add
                        </Button>
                        <Button type="secondary" className="ml-1" onClick={() => setLayout("import")}>
                            <ImportOutlined /> Import
                        </Button>
                    </span>
                }
            >
                <Table
                    rowKey={(x) => x.id}
                    dataSource={items}
                    loading={loading}
                    columns={[
                        {
                            title: "First",
                            dataIndex: "firstName",
                            sorter: (a, b) => {
                                return a.firstName.localeCompare(b.firstName);
                            },
                        },
                        {
                            title: "Last",
                            dataIndex: "lastName",
                            sorter: (a, b) => {
                                return a.lastName.localeCompare(b.lastName);
                            },
                        },
                        {
                            title: "Email",
                            dataIndex: "email",
                        },
                        {
                            title: "Sending",
                            dataIndex: "isActive",
                            align: "center",
                            render: (text, record) => (
                                <div className="text-center">
                                    {record.isActive && <CheckCircleTwoTone twoToneColor="#52c41a" />}
                                    {!record.isActive && <StopTwoTone twoToneColor="#aa3333" />}
                                </div>
                            ),
                        },
                        {
                            title: "Status",
                            dataIndex: "status",
                            align: "center",
                            render: (text, record) => <div>{renderStatus(record.status)}</div>,
                        },
                        {
                            title: "Actions",
                            align: "center",
                            key: "id",
                            render: (text, record) => (
                                <div className="text-center">
                                    <span onClick={() => handleEdit(record.id)} className="pointer">
                                        <EditTwoTone />
                                    </span>

                                    {!record.isDefault && (
                                        <span>
                                            <Divider type="vertical" />
                                            <span onClick={() => handleDelete(record.id)} className="pointer">
                                                <DeleteTwoTone />
                                            </span>
                                        </span>
                                    )}
                                </div>
                            ),
                        },
                    ]}
                />
            </Card>
        );
    } else {
        // import
        return (
            <Card
                title="Lead List"
                extra={
                    <Button type="primary" icon="backward" onClick={() => setLayout("grid")}>
                        Back
                    </Button>
                }
            >
                <Importer ref={importer} onSuccess={setSearch(runSearch + 1)} userId={data.id} />
            </Card>
        );
    }
}
