import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Layout from 'components/MasterLayout';
import SettingsList from './settingsList';

export default function Settings () {

    return (
        <Layout>
            <Routes>
                <Route index element={<SettingsList />} />
                <Route path="*" element={<SettingsList />} />
            </Routes>
        </Layout>
    );
};