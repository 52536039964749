import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Layout from 'components/MasterLayout';
import EventLogList from './eventLogList';

export default function EventLog () {

    return (
        <Layout>
            <Routes>
                <Route path="*" element={<EventLogList />} />
            </Routes>
        </Layout>
    );
};