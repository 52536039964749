import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Table, Button, Card } from "antd";
import { PlusOutlined, MenuOutlined } from '@ant-design/icons';
import { systemService } from 'services/SystemService.js';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { arrayMoveImmutable }  from 'array-move';
import { notify } from 'util/Notify';


const DragHandle = sortableHandle(() => (
    <MenuOutlined style={{ cursor: 'pointer', color: '#999' }} />
));

const SortableItem = sortableElement(props => <tr {...props} />);
const SortableContainer = sortableContainer(props => <tbody {...props} />);


export default function SystemPropertyList({ propertyType, entityId }) {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [pagination, setPagination] = useState({ current: 1, pageSize: 20, total: 0, position: ['topRight', 'bottomRight'], showSizeChanger: true });
    const [isDirty, setDirty] = useState(false);

    useEffect(() => {
        setLoading(true);

        let searchArgs = {
            keyword: "",
            orderBy: { id: 'lastname', desc: false },
            pagination: {
                pageNum: pagination.current,
                pageSize: pagination.pageSize
            },
            propertyType: propertyType, // 0 (user), 1 (contenttype), 2 (forms), 3 (systemlists)
            entityId: entityId || 0
        }

        systemService.searchSystemProperties(searchArgs)
            .then(data => {
                const pager = { ...pagination };
                pager.total = data.totalRecords;

                setItems(data.systemProperties);
                setPagination(pager);
            })
            .catch(err => console.log(err))
            .finally(() => setLoading(false))
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleNew = () => {        
        let newUrl = "";
        switch(propertyType) {
            case 1: // contenttype props
                newUrl = `/manage/cms/contenttype/${entityId}/systemprop`;
                break;
            case 2: // form props
                newUrl = `/manage/forms/form/${entityId}#editor`;
                break;
            case 3: // system list props
                newUrl = `/manage/lists/list/${entityId}/systemprop`;
                break
            default: // user props
                newUrl = '/manage/profileprops/profileprop';
                break;
        } 
        navigate(newUrl);
    }

    //#region Dragging
    const onSortEnd = ({ oldIndex, newIndex }) => {                
        if (oldIndex !== newIndex) {
            const newData = arrayMoveImmutable([].concat(items), oldIndex, newIndex).filter(el => !!el);

            setItems(newData);
            setDirty(true);
        }
    };
    
    const DraggableBodyRow = ({ className, style, ...restProps }) => {
        // function findIndex base on Table rowKey props and should always be a right array index        
        if(!items) return null;
        const index = items.findIndex(x => x.id === restProps['data-row-key']);
        return <SortableItem index={index} {...restProps} />;
    };
    //#endregion

    // save the list to the db
    const handleSave = () => {        
        setLoading(true);

        const data = {
            ids: items.map((o,i) => {
                return o.id;
            })
        }
        
        // save the order
        systemService.updateSystemPropertyOrder(data)
            .then((data) => {
                notify.success("The properties have been updated");
                
                setDirty(false);
            })
            .catch(err => notify.error(err))
            .finally(() => setLoading(false));
    }
    //#endregion


    const DraggableContainer = props => (
        <SortableContainer
            useDragHandle
            helperClass="row-dragging"
            onSortEnd={onSortEnd}
            {...props}
        />
    );

    return (
        <Card
            title="Custom Properties"
            extra={ <Button type="primary" icon={<PlusOutlined />} color="btn btn-light" onClick={handleNew} title="Add Property">Add Property</Button> }
        >

            <div>
                
                <Table
                    dataSource={items}
                    rowKey={x => x.id}
                    pagination={false}
                    loading={loading}
                    size="middle"
                    className="clear-both pointer mb-4"
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: e => {                                    
                                if(propertyType === 0) // user props
                                    navigate(`/manage/profileprops/profileprop/${record.id}`)
                                else if(propertyType === 1) // content type
                                    navigate(`/manage/cms/contenttype/${entityId}/systemprop/${record.id}`)
                                else if(propertyType === 2) // form
                                    navigate(`/manage/forms/form/${entityId}#editor`)
                                else // system list
                                    navigate(`/manage/lists/list/${entityId}/systemprop/${record.id}`);
                            }
                        }                                
                    }}
                    components={{
                        body: {
                            wrapper: DraggableContainer,
                            row: DraggableBodyRow,
                        },
                    }}
                    columns={[
                        {
                            title: '',
                            dataIndex: 'sort',
                            width: 30,
                            className: 'drag-visible',
                            render: () => <DragHandle />,
                        },
                        { title: 'Label', dataIndex: 'label', sorter: true, className: 'drag-visible' },
                        { title: 'Slug', dataIndex: 'slug', sorter: true },
                        { title: 'Type', dataIndex: 'fieldTypeText' },
                    ]}
                />

                <Button 
                    type="primary"
                    loading={loading}
                    disabled={!isDirty}                            
                    onClick={handleSave}
                >
                    Save
                </Button>
            </div>

        </Card>
    );   
}
