import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Layout from 'components/MasterLayout';
import User from './user';

export default function AcctManagement () {

    return (
        <Layout>
            <Routes>
                <Route path="*" element={<User />} />
            </Routes>
        </Layout>
    );
};
