import React, { useState, useRef, useEffect } from 'react';
import { Button, Modal, Form, Input, Row, Col } from 'antd';

/*
    Notes:
        presets: this is an array of label,value pairs.  they are used as possible options the user can select
*/

const SystemListModal = ({ operation, operations, onSubmit, onCancel, existing, loading, presets }) => {
    const [form] = Form.useForm();
    const [bulkForm] = Form.useForm();
    const labelRef = useRef(null);
    const [isBulk, setBulk] = useState(false);
    const hasPresets = presets?.length > 0;

    useEffect(() => {        
        setBulk(false);
        
        // set the fields if they are adding or updating
        if(operation === operations.Add) {
            form.setFieldsValue({ 
                id: generateId(),
                label: "",
                value: ""
            });
            bulkForm.setFieldsValue({ bulk: "" })
        }
        else
            form.setFieldsValue({ ...existing});

        if(operation) {
            setTimeout(() => labelRef.current.focus(), 200);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [operation])

    const onOk = (closeModal) => {

        if(!isBulk) {
            form.validateFields()
            .then(values => {
                form.resetFields();
                onSubmit(values, isBulk, closeModal);

                if(!closeModal) {
                    bulkForm.resetFields();
                    form.resetFields();
                    form.setFieldsValue({ id: generateId() });
                    labelRef.current.focus();
                }
                
                setBulk(false);
            })
            .catch(info => {
                console.log('Validate Failed:', info);
            });
        }
        else {
            bulkForm.validateFields()
            .then(values => {
                bulkForm.resetFields();
                form.resetFields();

                onSubmit(values, isBulk, closeModal);

                if(!closeModal) {
                    form.setFieldsValue({ id: generateId() });
                    labelRef.current.focus();
                }
                
                this.setState({ 
                    isBulk: false
                });
            })
            .catch(info => {
                console.log('Validate Failed:', info);
            });
        }
    }

    /// Add all of the label/value pairs that were passed in to this component
    const addPresets = () => {
        let b = bulkForm.getFieldValue("bulk") || "";
        if(b.length > 0) b += "\n";
        let opts = presets.map((o,i) => {
            return o.label + "|[[" + o.value + "]]"
        }).join("\n");

        bulkForm.setFieldsValue({ bulk: b + opts })
    }

    /// Add one of the label/value pairs that were passed in to this component
    const addPreset = (o) => {
        let b = bulkForm.getFieldValue("bulk") || "";
        if(b.length > 0) b += "\n";
        let opt = o.label + "|[[" + o.value + "]]";

        bulkForm.setFieldsValue({ bulk: b + opt })
    }

    return (
        <Modal
            title={operation && `${operation} Item`}
            visible={operation}
            okText={operation && (operation === operations.Edit ? "Save" : operation)}
            footer={[
                <Button key="back" onClick={onCancel}>Close</Button>,
                <Button key="submitBulk" loading={loading} onClick={() => setBulk(!isBulk)}>Bulk Import</Button>,
                <Button key="submit" type="primary" loading={loading} onClick={() => onOk(true)}>Save</Button>,
                <Button key="submitMore" type="primary" loading={loading} onClick={() => onOk(false)} className={operation === operations.Edit ? "d-none" : ""}>Save and Add Another</Button>,
            ]}
        >
            <Form
                name="termForm"
                layout="vertical"
                form={form}
                className={isBulk ? "d-none" : ""}
            >
                <Row gutter={[16,16]}>
                    <Col>
                        <Form.Item
                            name="label"
                            label="Label"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter the label for this item',
                                },
                            ]}
                        >
                            <Input ref={labelRef} />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            name="value"
                            label="Value"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter the value for this item',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item name="id" style={{ display: 'none' }}>
                    <Input />
                </Form.Item> 
            </Form>

            <Form
                name="bulkForm"
                layout="vertical"
                form={bulkForm}
                className={isBulk ? "" : "d-none"}
            >
                <Form.Item
                    name="bulk"
                    label="Enter in the format of: label or label|value"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter data',
                        },
                    ]}
                >
                    <Input.TextArea rows={6} />
                </Form.Item>

                {
                    hasPresets && 
                        <Form.Item>
                            <Button onClick={addPresets}>Add All Options</Button>
                            <hr />
                            <div className="columize-3">
                                {
                                    presets.map((o,i) => {
                                        return  <div key={i} onClick={() => addPreset(o)} className="pointer">{o.label}</div>
                                    })
                                }
                            </div>
                        </Form.Item>
                }
                
            </Form>        
      </Modal>
    );
  };

  
function generateId() {    
    return -1 * Math.floor(Math.random() * (999999 + 1));
}

export default SystemListModal;