import React, { useState } from "react";
import { Form, Input, Button, Drawer, Table, Tag, Popconfirm } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import ContentSearch from "./ContentSearch";

const ReferenceInput = ({
    slug,
    value,
    label,
    opts,
    form,
    onChange,
    references, // existing values
    debug,

    values,
    error,
    errors,
    className,
    placeholder,
    disabled,
}) => {
    const [list, setList] = useState(references || []);
    const [showSearch, setShowSearch] = useState(false);
    const isMany = opts.referenceAmount !== "1";

    if (!slug) {
        throw Error("ReferenceInput property: slug required");
    }

    if (!value && values && values[slug]) {
        value = values[slug];
    }
    if (!error && errors && errors[slug]) {
        error = errors[slug];
    }

    const handleSuccess = (items) => {
        const s = list.concat(items);
        setList(s);
        updateParent(s);
        setShowSearch(false);
    };

    const removeItem = (o) => {
        var idx = list.findIndex((i) => i.id === o.id);
        if (idx > -1) {
            let items = [...list];
            items.splice(idx, 1);
            setList(items);
            updateParent(items);
        }
    };

    // notify the parents as to this change
    const updateParent = (items) => {
        const csv = items.map((o, i) => o.id).join(",");
        form.setFieldsValue({ [slug]: csv });
        if (onChange) onChange(slug, csv);
    };

    return (
        <Form.Item label={label} className={className}>
            <div className="bordered p-2 text-center">
                {(isMany || (!isMany && list.length === 0)) && (
                    <Button type="primary" className="my-2" onClick={() => setShowSearch(true)}>
                        Select
                    </Button>
                )}

                {list.length > 0 && (
                    <Table
                        dataSource={list}
                        rowKey={(x) => x.id}
                        pagination={false}
                        size="middle"
                        className="clear-both mt-3"
                        columns={[
                            { title: "Title", dataIndex: "title" },
                            { title: "Type", dataIndex: "contentType" },
                            {
                                title: "Status",
                                render: (text, record) => (
                                    <span>
                                        {record.status === 0 && <Tag color="blue">Published</Tag>}
                                        {record.status === 1 && <Tag color="gold">Draft</Tag>}
                                        {record.status === 2 && <Tag color="magenta">Trashed</Tag>}
                                    </span>
                                ),
                            },
                            {
                                title: "",
                                width: "50px",
                                render: (text, record) => (
                                    <Popconfirm title="Are you sure you want to delete this?" onConfirm={() => removeItem(record)} okText="Yes" cancelText="No">
                                        <Button type="secondary" danger>
                                            <DeleteOutlined />
                                        </Button>
                                    </Popconfirm>
                                ),
                            },
                        ]}
                    />
                )}
            </div>

            <Form.Item name={slug} initialValue={value} hidden={!debug}>
                <Input />
            </Form.Item>

            <Drawer visible={showSearch} title="Search References" height={800} placement="bottom" onClose={() => setShowSearch(false)}>
                <ContentSearch contentTypes={opts.referenceTypes} isMany={isMany} onSuccess={handleSuccess} />
            </Drawer>
        </Form.Item>
    );
};

export default ReferenceInput;
