import axios from './AxiosHelper';

// define public methods
export const formService = {
    get,
    getById,
    getFormStats,
    search,
    searchSubmissions,
    getSubmission,
    post,
    saveFields,
    deleteForm,
    getActions,
    getAction,
    postAction,
    deleteAction
};


//#region Crud
function get() {
    return axios.getData(`/api/form`, true);
}

function getById(id) {
    return axios.getData(`/api/form/${id}`, true);
}

function getFormStats(id) {
    return axios.getData(`/api/form/${id}/stats`, true);
}

function search(data) {
    return axios.postData(`/api/form/search`, data, true);
}

function searchSubmissions(data) {
    return axios.postData(`/api/form/${data.id}/submissions`, data, true);
}

function getSubmission(formid, subid) {
    return axios.getData(`/api/form/${formid}/submissions/${subid}`, true);
}

function post(data) {
    return axios.upsertData(data.id, `/api/form`, data, true);
}

function saveFields(data) {
    return axios.putData(`/api/form/${data.id}/fields`, data, true);
}

function deleteForm(id) {
    return axios.deleteData(`/api/form/${id}`, true);
}

function getActions(id) {
    return axios.getData(`/api/form/${id}/actions`, true);
}

function getAction(formid, id) {
    return axios.getData(`/api/form/${formid}/actions/${id}`, true);
}

function postAction(data) {
    return axios.upsertData(data.id, `/api/form/${data.formId}/actions`, data, true);
}

function deleteAction(formid, id) {
    return axios.deleteData(`/api/form/${formid}/actions/${id}`);
}
//#endregion
