import React from "react";
import { Form, InputNumber } from "antd";

const NumberInput = props => {
    let {
        slug,
        value,
        label,
        opts,
        type,

        error,
        className,
        placeholder,
    } = props;

    if (!slug) {
        throw Error("NumberInput property: slug required");
    }
    
    return (
        <Form.Item
            validateStatus={error ? "error" : ""}
            name={slug}
            help={error ? error : ""}
            label={label}
            className={className}
            initialValue={value}
        >
            <InputNumber
                min={opts.minNumber || 0}
                max={opts.maxNumber || 9999999999}
                placeholder={placeholder}
                formatter={v => type === "currency" ? `$ ${v}` : v }
                parser={v => v.replace('$', '')}
            />
        </Form.Item>
    );
};
export default NumberInput;