import { Form, DatePicker, TimePicker } from "antd";

export default function DatePickerInput(props) {
    let {
        slug,
        value, // this must be set to a moment object outside of this function
        fieldType,
        label,
        disableDates,
        showTime,
        disabled,
    } = props;

    if (!slug) {
        throw Error("DatePickerInput property: slug required");
    }
    console.log("time", value);
    return (
        <Form.Item label={label}>
            {fieldType === 9 && (
                <Form.Item name={slug} initialValue={value}>
                    <TimePicker name={slug} use12Hours={true} />
                </Form.Item>
            )}

            {fieldType !== 9 && (
                <Form.Item name={slug} initialValue={value} help={Intl.DateTimeFormat().resolvedOptions().timeZone}>
                    <DatePicker
                        name={slug}
                        disabled={disabled}
                        disabledDate={disableDates}
                        showToday={false}
                        use12Hours
                        showTime={showTime ? { use12Hours: true, format: "HH:mm a" } : false}
                        format={showTime ? "MM-DD-YYYY h:mm a" : "MM-DD-YYYY"}
                    />
                </Form.Item>
            )}
        </Form.Item>
    );
}
