import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Form, Button, Input, Switch } from "antd";
import { notify } from "util/Notify";
import SystemContext from "context/SystemContext";
import { systemService } from "services/SystemService";

export default function Template() {
    const ctx = useContext(SystemContext);
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const { id } = useParams();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // if id doesn't exist
        if (!id) return;

        setLoading(true);

        // load up the user
        systemService
            .getEmailTemplate(ctx.portalId, id)
            .then((data) => {
                form.setFieldsValue({
                    name: data.name,
                    subject: data.subject,
                    body: data.body,
                    isActive: data.isActive,
                });
            })
            .catch((err) => {
                notify.error(err);
                console.log(err);
            })
            .finally(() => setLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOk = (values) => {
        setLoading(true);

        values.id = id;
        values.portalId = ctx.portalId;

        systemService
            .postEmailTemplate(values)
            .then((data) => {
                notify.success("You have successfully updated the template");
                navigate("/manage/templates");
            })
            .catch((err) => console.log(err))
            .finally(() => setLoading(false));
    };

    return (
        <Card
            title="Email Templates"
            extra={
                <span>
                    <Button color="btn btn-light" onClick={() => navigate("/manage/templates")} title="View All Users">
                        View All Templates
                    </Button>
                </span>
            }
        >
            <Form form={form} labelCol={{ span: 4 }} wrapperCol={{ span: 20 }} onFinish={handleOk}>
                <Form.Item label="Active" name="isActive" valuePropName="checked">
                    <Switch />
                </Form.Item>

                <Form.Item label="Name" name="name" rules={[{ required: true, message: "Please enter a name" }]}>
                    <Input placeholder="name" />
                </Form.Item>

                <Form.Item label="Subject" name="subject">
                    <Input placeholder="subject line" />
                </Form.Item>

                <Form.Item label="Body" name="body">
                    <Input.TextArea placeholder="email content" rows={5} />
                </Form.Item>

                <Form.Item wrapperCol={{ span: 20, offset: 4 }}>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    );
}
