import React, { useEffect, useState, useContext } from "react";
import { Button, Card, Form, Input, notification, Switch } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { systemService } from "services/SystemService.js";
import { useParams } from "react-router-dom";
import SystemContext from "context/SystemContext";

const SystemListEdit = ({ history }) => {
    const ctx = useContext(SystemContext);
    const { id } = useParams(0);
    const [form] = Form.useForm();
    // const [ list, setList ] = useState();
    const [saving, setSaving] = useState();

    // onload
    useEffect(() => {
        async function getList(id) {
            // get this list and all the content types
            const i = await systemService.getSystemList(id);

            // setList(i);

            // set form fields
            form.setFieldsValue({
                name: i.name,
                slug: i.slug,
                description: i.description,
                isCustom: i.isCustom,
            });
        }

        // load up the initial data
        if (id) getList(id);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    // save options
    const onSave = async (values) => {
        setSaving(true);

        let l = {
            id: parseInt(id, 10) || 0,
            name: values.name,
            slug: values.slug,
            description: values.description,
            isSystem: false,
            isCustom: values.isCustom,
            portalId: ctx.portalId,
        };

        await systemService
            .postSystemList(l)
            .then((data) => {
                openNotificationWithIcon("success", "Saved!", "List saved successfully.");

                // reload the page with the right url if it's a new one
                if (id === undefined) window.location.href = `/manage/lists/list/${data}`;
            })
            .catch((err) => {
                openNotificationWithIcon("error", "Not Saved!", err);
            });

        setSaving(false);
    };

    // update the slug to be valid
    const ensureSlug = () => {
        if (id > 0) return;
        const allValues = form.getFieldsValue();

        let slug =
            (allValues.slug &&
                allValues.slug
                    .trim()
                    .toLowerCase()
                    .replace(/ /g, "-")
                    .replace(/[^a-z0-9_-]/g, "")) ||
            "";
        if (slug.length === 0)
            slug =
                (allValues.name &&
                    allValues.name
                        .trim()
                        .toLowerCase()
                        .replace(/ /g, "-")
                        .replace(/[^a-z0-9_-]/g, "")) ||
                "";

        form.setFieldsValue({
            slug: slug,
        });
    };

    return (
        <Card>
            <div>
                <Form name="mainForm" layout="vertical" form={form} onFinish={onSave}>
                    <Form.Item name="name" label="Name" rules={[{ required: true, message: "required" }]}>
                        <Input onBlur={ensureSlug} />
                    </Form.Item>

                    <Form.Item name="slug" label="Slug" rules={[{ required: true, message: "required" }]}>
                        <Input disabled={id !== undefined} onBlur={ensureSlug} />
                    </Form.Item>

                    <Form.Item name="description" label="Description">
                        <Input />
                    </Form.Item>

                    <Form.Item name="isCustom" label="Use Multiple Fields" valuePropName="checked">
                        <Switch disabled={id !== undefined} />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" disabled={saving} icon={saving ? <LoadingOutlined /> : undefined}>
                            {saving ? "Saving..." : "Save"}
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </Card>
    );
};

export default SystemListEdit;

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message,
        description,
    });
};
