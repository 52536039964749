import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "components/SimpleLayout";
import { Spin, Alert, Card } from "antd";
import { NavLink } from "react-router-dom";
import { authenticationService } from "services/AuthenticationService";
import qs from "qs";
import SystemContext from "context/SystemContext";

export default function ConfirmEmail() {
    const ctx = useContext(SystemContext);
    const navigate = useNavigate();
    const [showError, setShowError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    useEffect(() => {
        const q = qs.parse(window.location.search.replace("?", ""));

        // make sure the needed values exist
        if (q.u === undefined || q.e === undefined) {
            navigate("/");
            return;
        }

        ctx.setLoading(true);

        let data = {
            userId: q.u,
            emailToken: q.e,
        };

        // try to confirm the email address
        authenticationService
            .confirmEmail(data)
            .then((data) => {
                ctx.setUser(data);
                window.location.href = "/"; // go home
            })
            .catch((err) => {
                setShowError(true);
                setErrorMsg("There was an error with token.");
            })
            .finally(() => ctx.setLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Layout>
            <Card className="login-container">
                <div className="mb-5 text-center">
                    <NavLink to={"/"} className="navbar-logo-container">
                        <img src="/assets/images/logo.png" className="img-fluid w-70 mb-5" alt="MerchRebate" />
                    </NavLink>
                    <br />
                    <br />

                    {ctx.loading && <Spin size="large" />}

                    {showError && <Alert message="Error" description={errorMsg} type="error" className="mx-auto w-100 mb-3" showIcon />}
                </div>
            </Card>
        </Layout>
    );
}
