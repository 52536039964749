import React from "react";
import { Table, Button, Card, Select, Row, Col, Popconfirm, Input } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { eventLogService } from "services/EventLogService";
import moment from "moment";
import { notify } from "util/Notify";

const { Search } = Input;

class EventLogList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            items: [],
            types: [],
            pagination: { current: 1, pageSize: 15, position: ["topRight", "bottomRight"] },
            sorted: { id: "createdon", desc: true },
            keyword: "",
            selectedKeys: [],
            selectedTypes: [],
            visible: false,
        };
    }

    componentDidMount() {
        this.loadTypes();
    }

    loadTypes = () => {
        this.setState({ loading: true });
        eventLogService
            .getTypes()
            .then((data) => {
                this.setState({
                    types: data,
                });

                // now load up the items
                this.load();
            })
            .catch((err) => console.log(err))
            .finally(() => {
                this.setState({ loading: false });
            });
    };

    load = () => {
        this.setState({ loading: true });

        let searchArgs = {
            keyword: this.state.keyword,
            orderBy: this.state.sorted,
            pagination: {
                pageNum: this.state.pagination.current,
                pageSize: this.state.pagination.pageSize,
            },
            types: this.state.selectedTypes,
        };

        eventLogService
            .search(searchArgs)
            .then((data) => {
                const pager = { ...this.state.pagination };
                pager.total = data.totalRecords;

                this.setState({
                    items: data.items,
                    pagination: pager,
                });
            })
            .catch((err) => console.log(err))
            .finally(() => {
                this.setState({ loading: false });
            });
    };

    handleTableChange = (paging, filters, sorter) => {
        let sorted = { ...this.state.sorted };
        const pager = { ...this.state.pagination };
        if (pager.pageSize !== paging.pageSize) {
            pager.pageSize = paging.pageSize;
            pager.current = 1;
        } else {
            pager.current = paging.current;
        }

        if (sorter.field) sorted = { id: sorter.field, desc: sorter.order === "descend" };

        this.setState(
            {
                pagination: pager,
                sorted,
                customers: [],
            },
            this.load
        );
    };

    // row selection
    handleSelection = (selectedRowKeys, selectedRows) => {
        let sel = selectedRowKeys.filter((i) => this.state.items.find((o) => o.id === i)); // make sure only selecting exising (non-spliced)

        // set keys to state
        this.setState({
            selectedKeys: sel,
        });
    };

    handleTypeChange = (ids) => {
        const pager = { ...this.state.pagination };
        pager.current = 1;
        this.setState(
            {
                pagination: pager,
                selectedTypes: ids,
            },
            this.load
        );
    };

    deleteSelected = () => {
        // make sure we have some, otherwise, just leave
        if (this.state.selectedKeys.length === 0) return;

        this.setState({ loading: true });

        eventLogService
            .deleteMany(this.state.selectedKeys)
            .then((data) => {
                notify.success("The selected items have been deleted");

                this.setState({ selectedKeys: [] });

                // reload the data
                this.load();
            })
            .catch((err) => notify.error(err))
            .finally(() => {
                this.setState({ loading: false });
            });
    };

    render() {
        return (
            <Card title="Event Log" extra={`${this.state.pagination.total} results`}>
                <Row gutter={[16, 16]} className="mb-2">
                    <Col span={12}>
                        <Popconfirm title="Are you sure you want to delete the selected items?" onConfirm={this.deleteSelected} okText="Yes" cancelText="No">
                            <Button type="secondary" danger disabled={!this.state.selectedKeys.length}>
                                <DeleteOutlined />
                            </Button>
                        </Popconfirm>
                    </Col>
                    <Col span={12} className="text-right">
                        <Select mode="multiple" placeholder="Select Type" value={this.state.selectedTypes} onChange={this.handleTypeChange} className="mr-2 w-40">
                            {this.state.types.map((o, i) => (
                                <Select.Option key={o.key} value={o.key}>
                                    {o.value}
                                </Select.Option>
                            ))}
                        </Select>

                        <Search
                            id="keyword"
                            placeholder="search"
                            onSearch={() => this.load()}
                            enterButton
                            className="w-50"
                            value={this.state.keyword}
                            onChange={(e) => this.setState({ keyword: e.target.value })}
                            loading={this.state.loading}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Table
                            dataSource={this.state.items}
                            rowKey={(x) => x.id}
                            pagination={this.state.pagination}
                            loading={this.state.loading}
                            onChange={this.handleTableChange}
                            size="small"
                            className="clear-both pointer"
                            expandable={{
                                expandedRowRender: (record) => (
                                    <span>
                                        {record.message}
                                        <br />
                                        {<DictionaryList data={record.params} />}
                                    </span>
                                ),
                            }}
                            rowSelection={{
                                type: "checkbox",
                                onChange: this.handleSelection,
                                selectedRowKeys: this.state.selectedKeys,
                            }}
                            columns={[
                                {
                                    title: "Created",
                                    dataIndex: "timeStamp",
                                    sorter: true,
                                    render: (text, record) => <span>{moment(record.timeStamp).format("LLL")}</span>,
                                },
                                { title: "Type", dataIndex: "logEventName", sorter: true },
                                { title: "Level", dataIndex: "level" },
                                { title: "User", dataIndex: "userName", sorter: true },
                            ]}
                        />
                    </Col>
                </Row>
            </Card>
        );
    }
}

export default EventLogList;

const hasJsonStructure = (str) => {
    if (typeof str !== "string") return false;
    try {
        const result = JSON.parse(str);
        const type = Object.prototype.toString.call(result);
        return type === "[object Object]" || type === "[object Array]";
    } catch (err) {
        return false;
    }
};

function DictionaryList(props) {
    let boo = hasJsonStructure(props.data);
    if (!boo) return <span></span>;

    var json = JSON.parse(props.data);

    let mapped = Object.keys(json).map((p, i) => {
        return (
            <div>
                {p}: {json[p]}
            </div>
        );
    });
    return mapped;
}
