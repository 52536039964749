import React from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Card, Button } from "antd";
import SystemPropertyEditor from 'modules/_Manage/SystemProps/components/SystemPropertyEditor';

const SystemListProp = () => {
    const { listid } = useParams();
    const navigate = useNavigate();

    return (
        <Card
            title="Custom Property"
            extra={ <Button color="btn btn-light" onClick={() => navigate(`/manage/lists/list/${listid}#props`)} title="View All">View All Properties</Button> }
        >
            <SystemPropertyEditor propertyType={3} entityId={listid} />
        </Card>
    );
};


export default SystemListProp;
