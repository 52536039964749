import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Layout from 'components/MasterLayout';
import TemplateList from './templateList';
import Template from './template';

export default function Templates () {

    return (
        <Layout>
            <Routes>
                <Route index element={<TemplateList />} />
                <Route path="template/:id" element={<Template />} />
                <Route path="template/" element={<Template />} />
                <Route path="*" element={<TemplateList />} />
            </Routes>
        </Layout>
    );
};