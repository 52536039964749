import React from "react";
import { FileAddOutlined } from "@ant-design/icons";
import { Upload } from "antd";
import SystemContext from "context/SystemContext";
import { notify } from "util/Notify";

const { Dragger } = Upload;

function beforeUpload(file) {
    // console.log(file);
    const isLessThan = file.size / 1024 / 1024 < 100;
    if (!isLessThan) {
        notify.error("File must smaller than 100MB");
    }
    return isLessThan;
}

class FileUploader extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
        };
    }

    handleChange = (info) => {
        if (info.file.status === "uploading") {
            this.setState({ loading: true });
            return;
        }

        if (info.file.status === "done") {
            // alert the parent with the url of the uploaded file (maybe handle multiples in the future)
            if (this.props.onUploaded) this.props.onUploaded(info.file.response);
        } else if (info.file.status === "error") {
            notify.error(`${info.file.name} file upload failed.`);
        }
    };

    render() {
        const headers = {
            Authorization: "Bearer " + this.context.getToken(),
        };

        // configure the correct UploadUrl
        const actionUrl = this.props.addToMediaLibrary
            ? `${process.env.REACT_APP_WEBAPI_URL}/api/media/files?addToMediaLibrary=true&portalId=${this.props.portalId}`
            : `${process.env.REACT_APP_WEBAPI_URL}/api/media/files?portalId=${this.props.portalId}`;

        const multiple = this.props.multiple !== undefined ? this.props.multiple : false;
        const showUploadList = this.props.showUploadList !== undefined ? this.props.showUploadList : true;

        if (this.props.portalId) {
            return (
                <div>
                    <Dragger
                        name="files"
                        accept={this.props.accept}
                        multiple={multiple}
                        beforeUpload={beforeUpload}
                        action={actionUrl}
                        headers={headers}
                        showUploadList={showUploadList}
                        onChange={this.handleChange}
                    >
                        <p className="ant-upload-drag-icon">
                            <FileAddOutlined />
                        </p>
                        <p className="ant-upload-text">Click or drag files to this area to upload</p>
                        {this.props.accept !== undefined && <p className="ant-upload-hint">{`must be only ${this.props.accept} files`}</p>}
                    </Dragger>
                </div>
            );
        } else {
            return <div>This component is misconfigured (missing portalId)</div>;
        }
    }
}

FileUploader.contextType = SystemContext;
export default FileUploader;
