import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Tabs, Row, Col, Card, Button } from 'antd';
import SystemListItems from './components/SystemListItems';
import SystemListEdit from './components/SystemListEdit';

const { TabPane } = Tabs;

const SystemList = () => {    
    const navigate = useNavigate();    
    const { id } = useParams();

    let defTab = 2; 

    // if it's a new one, then take them to the info tab
    if(id !== undefined) {        
        switch(window.location.hash) {
            case "#items": defTab = 1; break;
            case "#info": defTab = 2; break;
            default: defTab = 1;
        }
    }    

    return ( 

        <Card
            title="List"
            extra={ <Button color="btn btn-light" onClick={() => navigate('/manage/lists')} title="All Lists">View All Lists</Button> }
        >
            <Row>
                <Col span={24}>
                    
                    <Tabs defaultActiveKey={defTab.toString()}>
                        <TabPane tab="Items" key="1" disabled={id === undefined}>                        
                            <SystemListItems id={id} />                             
                        </TabPane>

                        <TabPane tab="Info" key="2">
                            <SystemListEdit id={id} />
                        </TabPane>
                    </Tabs>

                </Col>
            </Row>

        </Card>
    );
};

export default SystemList;