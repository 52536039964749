import React, { useEffect } from 'react';
import { Modal, Form, Input, Radio, Alert } from 'antd';
import IntlMessages from 'util/IntlMessages';

const SettingModal = ({ visible, onCreate, onCancel, existing }) => {
    const [form] = Form.useForm();

    useEffect(() => {
        if(existing)
            form.setFieldsValue({ ...existing });  
        else
        form.setFieldsValue({ 
            name: "",
            value: "",
            isPublic: false
        });
    });

    return (
      <Modal
        visible={visible}
        title={<IntlMessages id="manage.setting.modal.title"/>}
        okText={ existing ? "Update" : "Create" }
        cancelText="Cancel"
        getContainer={false}
        onCancel={onCancel}
        onOk={() => {
          form
            .validateFields()
            .then(values => {
              form.resetFields();
              onCreate(values);
            })
            .catch(info => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
        >
            <Form.Item
                name="name"
                label="Name"
                rules={[
                {
                    required: true,
                    message: 'Please enter a setting name',
                },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item name="value" label="Value">
                <Input placeholder="value" />
            </Form.Item>

            <Alert
                message="Privacy Warning"
                description="Marking public means this value will be available to any front end.  Do not mark public if it is supposed to be a server-only setting!"
                type="warning"
            />
                
            

            <Form.Item name="isPublic" label="Privacy" className="mb-0">
                <Radio.Group>
                    <Radio value={true}>Public</Radio>
                    <Radio value={false}>Private</Radio>
                </Radio.Group>
            </Form.Item>

        </Form>
      </Modal>
    );
  };

export default SettingModal;