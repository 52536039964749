import React from 'react';
import UserProfile from "./components/UserProfile";
import UserSettingsState from './context/UserSettingsState';

/*
    This is the admin view to see any user based on URL.  Note the isAdmin=true
*/

class User extends React.Component {
    
    render() {
        
        return (
            <UserSettingsState>
                <UserProfile isAdmin={true} />                
            </UserSettingsState>
        );
    }
}

export default User;