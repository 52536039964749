import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Form, Input, Button, Radio } from "antd";
import { credentialService } from "services/CredentialService";
import { notify } from "util/Notify";

export default function Agency() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    // onload
    useEffect(() => {
        async function getItem(id) {
            // get this list and all the content types
            if (id) {
                const i = await credentialService.getAgency(id);

                // set form fields
                form.setFieldsValue({ ...i });
            }
        }

        // load up the initial data
        getItem(id);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    // save
    const onSave = async (values) => {
        setLoading(true);

        values.id = parseInt(id, 10) || 0;

        await credentialService
            .saveAgency(values)
            .then((data) => {
                notify.success(`Agency was saved`, () => navigate(`/manage/credentials/agencies`));
            })
            .catch((err) => {
                notify.error(err);
            })
            .finally(() => setLoading(false));
    };

    return (
        <Card
            title="Agency"
            extra={
                <Button color="btn btn-light" onClick={() => navigate("/manage/credentials/agencies")} title="All Agencies">
                    View All Agencies
                </Button>
            }
        >
            <Form name="mainForm" form={form} layout="vertical" onFinish={onSave}>
                <Form.Item name="name" label="Name" rules={[{ required: true, message: "required" }]}>
                    <Input />
                </Form.Item>

                <Form.Item name="abbreviation" label="Abbreviation" rules={[{ required: true, message: "required" }]}>
                    <Input />
                </Form.Item>

                <Form.Item name="status" label="Status">
                    <Radio.Group>
                        <Radio value="active">Active</Radio>
                        <Radio value="implicit">Implicit</Radio>
                        <Radio value="disabled">Disabled</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item name="emails" label="Approver Emails (comma-delimited)">
                    <Input placeholder="approver@domain.com,approver2@otherdomain.com" />
                </Form.Item>

                <Form.Item name="websiteUrl" label="Website URL" rules={[{ required: false, message: "required" }]}>
                    <Input placeholder="https://domain.com" />
                </Form.Item>

                <Form.Item name="externalId" label="External Id" disabled={true}>
                    <Input disabled={true} />
                </Form.Item>

                <div>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        Submit
                    </Button>
                </div>
            </Form>
        </Card>
    );
}
