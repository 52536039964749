import React from "react";
import { Form, Input } from "antd";
import Wysiwyg from 'components/ui/Wysiwyg';

const WysiwygInput = props => {
    let {
        slug,
        value,        
        label,
        form,
        onChange,
        className,
        error,
        debug
    } = props;

    if (!slug) {
        throw Error("TextInput property: slug required");
    }

     // notify the parents as to this change
     const updateParent = (val) => {
        form.setFieldsValue({[slug]: val});
        if(onChange)
            onChange(slug, val);
    }

    return (
        <Form.Item
            validateStatus={error ? "error" : ""}
            help={error ? error : ""}
            label={label}
            className={className}
        >
            <Wysiwyg 
                value={value}
                name={`wysiwyg_${slug}`}
                onChange={updateParent} />
            
            <Form.Item name={slug}
                initialValue={value}
                hidden={!debug}>
                <Input.TextArea name="body" placeholder="body" rows={5} />                        
            </Form.Item>            
        </Form.Item>
    );
};


export default WysiwygInput;