import { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import { Table, Button, Input, Tag, Select, Row, Col } from "antd";
// import { contentService } from 'services/ContentService.js';
// import SystemContext from "context/SystemContext";
// import { notify } from "util/Notify";
import moment from "moment";

const { Search } = Input;

export default function Content({ contentTypes, isMany, isEcommerce, onSuccess }) {
    //const ctx = useContext(SystemContext);
    const [loading, setLoading] = useState(false);
    const [content, setContent] = useState([]);
    const [runSearch, setSearch] = useState(0);
    const [keyword, setKeyword] = useState("");
    const [sorted, setSorted] = useState({ id: "createdOn", desc: true });
    const [pagination, setPagination] = useState({ current: 1, pageSize: 20, total: 0, position: ["topRight", "bottomRight"], showSizeChanger: true });
    const [status, setStatus] = useState([0]);
    // const [statusupdate, setStatusUpdate] = useState(null);
    const [selectedKeys, setSelectedKeys] = useState([]);

    useEffect(() => {
        setLoading(false);
        /*
        // structure the args
        let data = {
            keyword: keyword,
            contentType: contentTypes || [],
            status: status,
            orderBy: sorted,
            pagination: {
                pageNum: pagination.current,
                pageSize: pagination.pageSize,
            },
            isEcommerce,
            portalId: ctx.portalId,
        };

        
        contentService.get(data)
            .then(data => {
                const pager = { ...pagination };
                pager.total = data.totalRecords;

                setContent(data.content);
                setPagination(pager);
            })
            .catch(err => console.log(err))
            .finally(() => {         
                setLoading(false);
            })
            */
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [runSearch]);

    const handleStatusChange = (ids) => {
        setStatus(ids);
        setSearch(runSearch + 1);
    };

    const handleTableChange = (paging, filters, sorter) => {
        let s = { ...sorted };
        const pager = { ...pagination };
        if (pager.pageSize !== paging.pageSize) {
            pager.pageSize = paging.pageSize;
            pager.current = 1;
        } else {
            pager.current = paging.current;
        }

        if (sorter.field) s = { id: sorter.field, desc: sorter.order === "descend" };

        setPagination(pager);
        setSorted(s);
        setSearch(runSearch + 1);
    };

    // row selection
    const handleSelection = (selectedRowKeys, selectedRows) => {
        let sel = selectedRowKeys.filter((i) => content.find((o) => o.id === i)); // make sure only selecting exising (non-spliced)

        // set keys to state
        setSelectedKeys(sel);
    };

    const handleSubmit = (o) => {
        if (!onSuccess) return;

        // if one item only
        if (o) {
            onSuccess([o]);
            return;
        }

        // if multiples, select the full content data rows and pass them to parent
        onSuccess(content.filter((o) => selectedKeys.find((i) => i === o.id)));
        setSelectedKeys([]);
    };

    return (
        <div>
            <Row gutter={[16, 16]} className="mb-3">
                <Col xs={12}>
                    <Select mode="multiple" placeholder="Select Status" value={status} onChange={handleStatusChange} className="w-100">
                        <Select.Option value={0}>Published</Select.Option>
                        <Select.Option value={1}>Draft</Select.Option>
                        <Select.Option value={2}>Trashed</Select.Option>
                    </Select>
                </Col>
                <Col xs={12}>
                    <Search
                        id="keyword"
                        placeholder="search"
                        onSearch={() => setSearch(runSearch + 1)}
                        enterButton
                        className="w-100"
                        value={keyword}
                        onChange={(e) => setKeyword(e.target.value)}
                        loading={loading}
                    />
                </Col>
                {isMany && (
                    <Col xs={24}>
                        <Button type="primary" className="mr-2" disabled={selectedKeys.length === 0} onClick={() => handleSubmit()}>
                            Select
                        </Button>
                    </Col>
                )}
            </Row>

            <Table
                dataSource={content}
                rowKey={(x) => x.id}
                pagination={pagination}
                loading={loading}
                size="middle"
                className="clear-both pointer"
                onChange={handleTableChange}
                rowSelection={
                    isMany && {
                        type: "checkbox",
                        onChange: handleSelection,
                        selectedRowKeys: selectedKeys,
                        hideSelectAll: true,
                    }
                }
                onRow={(record, rowIndex) => {
                    return {
                        onClick: (e) => {
                            // only handle clicking on the row when single selecting
                            !isMany && handleSubmit(record);
                        },
                    };
                }}
                columns={[
                    { title: "Title", dataIndex: "title", sorter: true },
                    { title: "Type", dataIndex: "contentType", sorter: true },
                    {
                        title: "Status",
                        render: (text, record) => (
                            <span>
                                {record.status === 0 && <Tag color="blue">Published</Tag>}
                                {record.status === 1 && <Tag color="gold">Draft</Tag>}
                                {record.status === 2 && <Tag color="magenta">Trashed</Tag>}
                            </span>
                        ),
                    },
                    {
                        title: "Created",
                        dataIndex: "createdOn",
                        sorter: true,
                        render: (text, record) => <span>{moment(text).format("LLL")}</span>,
                    },
                ]}
            />
        </div>
    );
}
