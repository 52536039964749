import React, { Component } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import MarkdownEditor from "ckeditor5-build-classic-simple-upload"; // this is built and then placed into the root/vendor/ folder
import SystemContext from "context/SystemContext";

/*
    Props:
        value -> value of the html
        name -> name of the editor
        onChange -> callback function

    If you ever need to update the CKEditor, we're using a custom build, which uses the SimpleUploadAdapter.  
    Instructions on how to do it resemble one of the answers here: https://stackoverflow.com/questions/46765197/how-to-enable-image-upload-support-in-ckeditor-5
    and: https://github.com/ckeditor/ckeditor5/issues/6344
    and: https://npmjs.com/package/ckeditor5-simple-upload
    and: https://ckeditor.com/docs/ckeditor5/latest/builds/guides/development/custom-builds.html (how to create a custom build)
*/

class Wysiwyg extends Component {
    static contextType = SystemContext;

    render() {
        const headers = {
            "X-CSRF-TOKEN": "CSFR-Token",
            Authorization: "Bearer " + this.context.getToken(),
        };

        return (
            <div className="App">
                <CKEditor
                    editor={MarkdownEditor}
                    config={{
                        simpleUpload: {
                            uploadUrl: `${process.env.REACT_APP_WEBAPI_URL}/api/media/image?portalId=${this.context.portalId}`,
                            headers: headers,
                        },
                        codeBlock: {
                            languages: [
                                { language: "plaintext", label: "Plain text" }, // The default language.
                                { language: "cs", label: "C#" },
                                { language: "css", label: "CSS" },
                                { language: "html", label: "HTML" },
                                { language: "javascript", label: "JavaScript" },
                                { language: "php", label: "PHP" },
                                { language: "typescript", label: "TypeScript" },
                                { language: "xml", label: "XML" },
                            ],
                        },
                    }}
                    data={this.props.value || ""}
                    onReady={(editor) => {
                        // You can store the "editor" and use when it is needed.
                        // console.log(Array.from( editor.ui.componentFactory.names() ));
                    }}
                    onChange={(event, editor) => {
                        const data = editor.getData();
                        // console.log( { event, editor, data } );

                        if (this.props.onChange) {
                            // console.log(data);
                            this.props.onChange(data, this.props.name);
                        }
                    }}
                    onBlur={(event, editor) => {
                        // console.log( 'Blur.', editor );
                    }}
                    onFocus={(event, editor) => {
                        // console.log( 'Focus.', editor );
                    }}
                />
            </div>
        );
    }
}

export default Wysiwyg;
