import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { credentialService } from "services/CredentialService.js";
import { Table, Card, Input, Row, Col, Select } from "antd";
import moment from "moment";

const { Search } = Input;

export default function VerificationQueue() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [runSearch, setSearch] = useState(0);
    const [list, setList] = useState([]);
    const [keyword, setKeyword] = useState("");
    const [status, setStatus] = useState(1);
    const [sorted, setSorted] = useState({ id: "createdon", desc: true });
    const [pagination, setPagination] = useState({ current: 1, pageSize: 20, total: 0, position: ["topRight", "bottomRight"], showSizeChanger: true });

    useEffect(() => {
        setLoading(true);

        var args = {
            keyword,
            orderBy: sorted,
            pagination: {
                pageNum: pagination.current,
                pageSize: pagination.pageSize,
            },
        };
        if (status > -1) args.status = status;

        // get credentials
        credentialService
            .searchVerifications(args)
            .then((data) => {
                setList(data.items);

                const pager = { ...pagination };
                pager.total = data.totalRecords;
                setPagination(pager);
            })
            .catch((err) => console.log(err))
            .finally(() => setLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [runSearch]);

    const handleTableChange = (paging, filters, sorter) => {
        let s = { ...sorted };
        const pager = { ...pagination };
        if (pager.pageSize !== paging.pageSize) {
            pager.pageSize = paging.pageSize;
            pager.current = 1;
        } else {
            pager.current = paging.current;
        }

        if (sorter.field) s = { id: sorter.field, desc: sorter.order === "descend" };

        setSorted(s);
        setPagination(pager);
        setSearch(runSearch + 1);
    };

    const handleTypeChange = (s) => {
        const pager = { ...pagination };
        pager.current = 1;
        setPagination(pager);
        setStatus(s);
        setSearch(runSearch + 1);
    };

    return (
        <Card title={"Verification Queue"}>
            <Row gutter={[16, 16]} className="mb-2">
                <Col sm={24} md={16} className="text-right">
                    <Select placeholder="Select Status" value={status} onChange={handleTypeChange} className="mr-2 w-40">
                        <Select.Option value={-1}>Any Status</Select.Option>
                        <Select.Option value={0}>Unknown</Select.Option>
                        <Select.Option value={1}>Pending</Select.Option>
                        <Select.Option value={2}>Verified</Select.Option>
                        <Select.Option value={3}>Not Verified</Select.Option>
                        <Select.Option value={4}>Expired</Select.Option>
                    </Select>
                </Col>
                <Col sm={24} md={8} className="text-right">
                    <Search
                        id="keyword"
                        placeholder="search"
                        onSearch={() => setSearch(runSearch + 1)}
                        enterButton
                        className="w-100"
                        value={keyword}
                        onChange={(e) => setKeyword(e.target.value)}
                        loading={loading}
                    />
                </Col>
            </Row>

            <Table
                dataSource={list}
                rowKey={(x) => x.id}
                pagination={pagination}
                loading={loading}
                size="middle"
                className="clear-both pointer"
                onChange={handleTableChange}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: (e) => {
                            navigate(`/manage/credentials/queue/verify/${record.id}`);
                        },
                    };
                }}
                columns={[
                    { title: "First", dataIndex: "firstName", sorter: true, render: (text, record) => <span>{record.user.firstName}</span> },
                    { title: "Last", dataIndex: "lastName", sorter: true, render: (text, record) => <span>{record.user.lastName}</span> },
                    { title: "Email", dataIndex: "email", sorter: true, render: (text, record) => <span>{record.user.email}</span> },

                    { title: "Agency", dataIndex: "credentialAgencyName", sorter: true },
                    { title: "Credential", dataIndex: "credentialCertificationName", sorter: true },
                    { title: "Status", dataIndex: "statusName" },
                    {
                        title: "Created",
                        dataIndex: "createdOn",
                        sorter: true,
                        render: (text, record) => <span>{moment(record.createdOn).format("LL")}</span>,
                    },
                    {
                        title: "Verified",
                        dataIndex: "verifiedOn",
                        sorter: true,
                        render: (text, record) => <span>{record.verifiedOn && moment(record.verifiedOn).format("LL")}</span>,
                    },
                ]}
            />
        </Card>
    );
}
