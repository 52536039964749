import React from 'react';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { Table, Button, Card, Input, Tag, Select, Row, Col, Popconfirm } from "antd";
import MediaLibraryContext from '../context/MediaLibraryContext';
import MediaModal from "./MediaModal";
import moment from 'moment';

const { Search } = Input;

class MediaList extends React.Component {
    static contextType = MediaLibraryContext;

    componentDidMount() {
        this.context.search();        
    }

    render() {

        return (
            <Card
                title="Media Library"
                extra={ <Button type="primary" icon={<PlusOutlined />} color="btn btn-light" onClick={this.context.toggleModal} title="Add Media">Add Media</Button> }
            >

                <Row gutter={[16,16]}>
                    <Col span={12}>
                        <Popconfirm
                            title="Are you sure you want to delete the selected items?"
                            onConfirm={this.context.deleteSelected}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="secondary" danger disabled={!this.context.selectedKeys.length}><DeleteOutlined /></Button>
                        </Popconfirm>       
                    </Col>
                    <Col span={12} className="text-right mb-2">
                        
                        <Select 
                            mode="multiple"
                            placeholder="Select Type"
                            value={this.context.mediaTypes}
                            onChange={this.context.handleMediaTypeChange}
                            className="mr-2 w-40">
                            <Select.Option value={0}>Image</Select.Option>
                            <Select.Option value={1}>File</Select.Option>
                            <Select.Option value={2}>Video</Select.Option>
                            <Select.Option value={3}>Audio</Select.Option>
                            <Select.Option value={4}>Embedded</Select.Option>
                        </Select>

                        <Search
                            id="keyword"
                            placeholder="search"
                            onSearch={this.context.handleSearch}
                            enterButton
                            className="w-50"
                            value={this.context.keyword}
                            onChange={this.context.handleFieldChange}
                            loading={this.context.loading}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Table
                            dataSource={this.context.medialist}
                            rowKey={x => x.id}
                            pagination={this.context.pagination}
                            loading={this.context.loading}
                            size="middle"
                            className="clear-both pointer"
                            onChange={this.context.handleTableChange}                        
                            onRow={(record, rowIndex) => {
                                return {
                                    onClick: () => this.context.setMedia(record)
                                }                                
                            }}
                            rowSelection={{
                                type: 'checkbox',
                                onChange: this.context.handleSelection,
                                selectedRowKeys: this.context.selectedKeys
                            }}
                            columns={[
                                {
                                    title: '', dataIndex: 'thumbnail', width: '6%', responsive: ['lg'],
                                    render: (text,record) => (
                                        <img src={record.thumbnail} alt="Thumbnail" className="img-fluid avatar-50" />
                                    )
                                },
                                { title: 'Title', dataIndex: 'title', sorter: true },
                                { title: 'Name', dataIndex: 'name', sorter: true, responsive: ['xl'], ellipsis: true },
                                {
                                    title: 'Type', dataIndex: 'lastName', sorter: true,
                                    render: (text,record) => (
                                        <span>
                                            { record.mediaType === 0 && <Tag color="blue">Image</Tag> }
                                            { record.mediaType === 1 && <Tag color="gold">File</Tag> }
                                            { record.mediaType === 2 && <Tag color="cyan">Video</Tag> }
                                            { record.mediaType === 3 && <Tag color="magenta">Audio</Tag> }
                                            { record.mediaType === 4 && <Tag color="volcano">Embedded</Tag> }
                                        </span>                                    
                                    )
                                },
                                {
                                    title: 'Dimensions', responsive: ['xl'],
                                    render: (text,record) => (
                                        <span>
                                            { record.mediaType === 0 && <span>{record.width} x {record.height}</span> }
                                            { record.mediaType !== 0 && <span></span> }
                                        </span>
                                    )
                                },
                                {
                                    title: 'Created', dataIndex: 'createdOn', sorter: true, responsive: ['xl'],
                                    render: (text,record) => (
                                        <span>{ moment(record.createdOn).format('LL')}</span>
                                    )
                                }
                            ]}
                        />
                    </Col>
                </Row>
                        
                <MediaModal />

            </Card>
        );
    }
}

export default MediaList;