import { useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import SystemContext from "context/SystemContext";

import Dashboard from "./_Manage/Dashboard";
import EventLog from "./_Manage/EventLog";
import Security from "./_Manage/Security";
import Settings from "./_Manage/Settings";
import SystemLists from "./_Manage/SystemLists";
import SystemProps from "./_Manage/SystemProps";
import Templates from "./_Manage/Templates";
import MediaLibrary from "./_Manage/MediaLibrary";

import Credentials from "./_Manage/Credentials";

/*   
    Dashboard, // admin landing page
    MediaLibrary, // UI for media management
    EventLog, // displays activity in the system
    SystemLists, // manage generic lists
    Templates, // edit system email templates
    Settings, // manage system settings
    SystemProps, // extended props for users and content types
    Security, // manage users and roles
*/

export default function Modules() {
    const ctx = useContext(SystemContext);
    const authUser = ctx.getUser();

    return (
        <>
            <RequireAuth redirectTo="/login" authUser={authUser}>
                <Routes>
                    <Route path="dashboard/*" element={<Dashboard />} />
                    <Route path="media/*" element={<MediaLibrary />} />
                    <Route path="eventlog/*" element={<EventLog />} />
                    <Route path="security/*" element={<Security />} />
                    <Route path="settings/*" element={<Settings />} />
                    <Route path="lists/*" element={<SystemLists />} />
                    <Route path="profileprops/*" element={<SystemProps />} />
                    <Route path="templates/*" element={<Templates />} />

                    <Route path="credentials/*" element={<Credentials />} />
                </Routes>
            </RequireAuth>
        </>
    );
}

function RequireAuth({ children, redirectTo, authUser }) {
    return authUser ? children : <Navigate to={redirectTo} />;
}
