import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Layout from 'components/MasterLayout';
import SystemListAll from './systemListAll';
import SystemList from './systemList';
import SystemListProp from './components/SystemListProp';

export default function SystemLists () {
    
    return (
        <Layout>
            <Routes>
                <Route path="list/:listid/systemprop/:id" element={<SystemListProp />} />
                <Route path="list/:listid/systemprop/" element={<SystemListProp />} />
                <Route path="list/:id" element={<SystemList />} />
                <Route path="list/" element={<SystemList />} />
                <Route path="*" element={<SystemListAll />} />
            </Routes>
        </Layout>
    );
};