import React, { useContext, useState } from "react";
import { LogoutOutlined, UserOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { Layout, Menu, Dropdown, Avatar, Drawer, Divider } from "antd";
import { Link } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import SystemContext from "context/SystemContext";
import SiteMenu from "./SiteMenu";

const { Content, Header, Sider } = Layout;

export default function MasterLayout(props) {
    const ctx = useContext(SystemContext);
    const [collapsed, setCollapsed] = useState(false);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const useSideMenu = false;
    // const [useSideMenu, setUseSideMenu] = useState(false);

    const toggle = () => {
        if (window.innerWidth >= 992) setCollapsed(!collapsed);
        else setDrawerVisible(!drawerVisible);
    };

    const userSignOut = (e) => {
        ctx.logout();
        window.location.href = "/";
        e.preventDefault();
        return false;
    };

    let userMenu = (
        <Menu>
            <Menu.Item key="user">
                <Link className="dropdown-item text-muted" to="/accountmanagement/user">
                    <UserOutlined className="mr-1" />
                    <IntlMessages id="popup.profile" />
                </Link>
            </Menu.Item>
            <Menu.Item key="logout">
                <a className="dropdown-item text-muted" href="/" onClick={userSignOut}>
                    <LogoutOutlined className="mr-1" />
                    <IntlMessages id="popup.logout" />
                </a>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="version">Version: {ctx.system && ctx.system.version}</Menu.Item>
        </Menu>
    );

    let thumbnail = ctx.user.thumbnailUrl !== "" ? <Avatar size={50} src={ctx.user.thumbnailUrl} /> : <Avatar size={50} icon={<UserOutlined />} class="light" />;

    return (
        <Layout className="wrapper">
            {useSideMenu && (
                <Drawer
                    title={
                        <Link to={"/"} className="navbar-logo-container">
                            <img src="/assets/images/logo.png" className="navbar-logo" alt="Logo" />
                        </Link>
                    }
                    placement="left"
                    closable={false}
                    getContainer={false}
                    onClose={toggle}
                    visible={drawerVisible}
                >
                    <SiteMenu mode="inline" />
                </Drawer>
            )}

            {useSideMenu && (
                <Sider
                    trigger={null}
                    collapsible
                    collapsed={collapsed}
                    collapsedWidth="0"
                    breakpoint="lg"
                    className="side-nav"
                    onBreakpoint={(broken) => {
                        setCollapsed(broken);
                    }}
                >
                    <SiteMenu mode="inline" />
                </Sider>
            )}

            <Layout>
                <Header className="navbar">
                    <Link to={"/"} className="navbar-logo-container">
                        <img src="/assets/images/logo.png" className="navbar-logo" alt="logo" />
                    </Link>
                    <Divider type="vertical" />

                    {useSideMenu && (
                        <span className="trigger" onClick={toggle}>
                            {collapsed && <MenuUnfoldOutlined className="anticon-menu" />}
                            {!collapsed && <MenuFoldOutlined className="anticon-menu" />}
                        </span>
                    )}

                    {!useSideMenu && (
                        <div className="d-ib navbar-menu">
                            <SiteMenu mode="horizontal" />
                        </div>
                    )}

                    <div className="ml-auto mr-0">
                        <Dropdown trigger={["click"]} overlay={userMenu}>
                            {thumbnail}
                        </Dropdown>
                    </div>
                </Header>
                <Content className="container">{props.children}</Content>
            </Layout>
        </Layout>
    );
}
