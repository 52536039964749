import axios from "./AxiosHelper";

// define public methods
export const systemService = {
    get,
    searchSystemProperties,
    getSystemPropertyById,
    postSystemProperty,
    updateSystemPropertyOrder,

    getFieldTypes,
    getRenewalTypes,

    getSystemLists,
    getSystemList,
    getSystemListItemProperties,
    postSystemList,
    putSystemListItems, // saving all at once
    postSystemListItem, // upserting one item as a time
    deleteSystemList,
    // deleteSystemListItems

    getEmailTemplates,
    getEmailTemplate,
    postEmailTemplate,
    deleteEmailTemplate,

    publishExternal,
    getDashboardStats,

    importContent,
    exportContent,
};

/* System Properties */
function get() {
    return axios.getData(`/api/system`, true);
}

function searchSystemProperties(data) {
    return axios.postData(`/api/system/systemproperties/search`, data, true);
}

function getSystemPropertyById(id) {
    return axios.getData(`/api/system/systemproperties/${id}`, true);
}

function postSystemProperty(values) {
    return axios.upsertData(values.id, `/api/system/systemproperties`, values, true);
}

function updateSystemPropertyOrder(values) {
    return axios.putData(`/api/system/systemproperties/reorder`, values, true);
}

/* Extra */
function getFieldTypes() {
    return axios.getData("/api/system/lookup/fieldtypes", true);
}

function getRenewalTypes() {
    return axios.getData("/api/system/lookup/renewaltypes", true);
}

/* System Lists */
function getSystemLists() {
    return axios.getData(`/api/system/list`, false);
}

function getSystemList(id) {
    return axios.getData(`/api/system/list/${id}`, true);
}

function getSystemListItemProperties(portalId, listid, id) {
    return axios.getData(`/api/system/list/${portalId}/${listid}/${id}/properties`, true);
}

function postSystemList(values) {
    return axios.upsertData(values.id, `/api/system/list`, values, true);
}

function postSystemListItem(portalId, listid, id, values) {
    return axios.postData(`/api/system/list/${portalId}/${listid}/${id}/items`, values, true);
}

function putSystemListItems(values) {
    return axios.putData(`/api/system/list/${values.id}/items`, values, true);
}

function deleteSystemList(id) {
    return axios.deleteData(`/api/system/list/${id}`, true);
}

function getEmailTemplates(portalId) {
    return axios.getData(`/api/system/template/${portalId}`, true);
}

function getEmailTemplate(portalId, id) {
    return axios.getData(`/api/system/template/${portalId}/${id}`, true);
}

function postEmailTemplate(values) {
    return axios.upsertData(values.id, `/api/system/template/${values.portalId}`, values, true);
}

function deleteEmailTemplate(portalId, id) {
    return axios.deleteData(`/api/system/template/${portalId}/${id}`, true);
}

function publishExternal() {
    return axios.postData(`/api/system/publishexternal`, {}, true);
}

function getDashboardStats() {
    return axios.getData(`/api/system/stats`, true);
}

//#region Import/Export
function importContent(data) {
    return axios.postData(`/api/system/import`, data, true);
}

function exportContent(data) {
    return axios.postData(`/api/system/export`, data, true);
}
//#endregion
