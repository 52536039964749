import { Row, Col, Card } from "antd";

export default function Dash() {
    return (
        <Card title="Management Dashboard">
            <div className="ecommerce-summary">
                <Row gutter={[16, 16]}>
                    <Col md={6} sm={12} xs={24}>
                        Dashboard
                    </Col>
                </Row>
            </div>
        </Card>
    );
}
