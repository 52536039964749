import React, { useContext, useEffect } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import { isIOS, isMobile } from "react-device-detect";
import { IntlProvider } from "react-intl";
import AppLocale from "util/LangProvider";
import SystemContext from "context/SystemContext";
import Home from "./modules/Home";
import Login from "./modules/AccountManagement/login";
import ConfirmEmail from "./modules/AccountManagement/confirmEmail";
import ConfirmPassword from "./modules/AccountManagement/confirmPassword";
import PageNotFound from "./modules/Misc/404";
import AccountManagement from "./modules/AccountManagement";
import Leads from "./modules/Leads";
import Modules from "./modules";

export default function App() {
    const ctx = useContext(SystemContext);
    const location = useLocation();
    const authUser = ctx.getUser();

    useEffect(() => {
        // per environment, then add class to change the bg color
        if (window.location.href.indexOf("localhost") > -1) document.getElementById("root").className += " root-dev";
        if (window.location.href.indexOf("staging") > -1) document.getElementById("root").className += " root-staging";
    }, []);

    // forward to the landing page
    if (location.pathname === "/") {
        if (authUser === null) {
            return <Navigate to={"/login"} />;
        } else {
            return <Navigate to={"/manage/dashboard"} />;
        }
    }

    //set default height and overflow for iOS mobile Safari 10+ support.
    if (isIOS && isMobile) {
        document.body.classList.add("ios-mobile-view-height");
    } else if (document.body.classList.contains("ios-mobile-view-height")) {
        document.body.classList.remove("ios-mobile-view-height");
    }

    const currentAppLocale = AppLocale["en"];

    return (
        <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
            <div id="app-main" className="app-main">
                <Routes>
                    <Route path={`home`} element={<Home />} />
                    <Route path={`login`} element={<Login />} />
                    <Route path={`confirmemail`} element={<ConfirmEmail />} />
                    <Route path={`confirmpassword`} element={<ConfirmPassword />} />

                    <Route path={`accountmanagement/*`} element={<AccountManagement />} />
                    <Route path={`leads/*`} element={<Leads />} />

                    <Route path="manage/*" element={<Modules />} />

                    <Route path="*" element={<PageNotFound />} />
                </Routes>
            </div>
        </IntlProvider>
    );
}
