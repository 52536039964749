import React from 'react';
import MediaList from './components/MediaList';
import MediaLibraryState from './context/MediaLibraryState';

export default function Media() {
    return ( 
        <MediaLibraryState>
            <MediaList />
        </MediaLibraryState>
    )
}