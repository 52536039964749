import { useState, useEffect, useContext } from "react";
import { Table, Button, Tabs, Input, Tag, Select, Row, Col } from "antd";
import { mediaService } from "services/MediaService.js";
import SystemContext from "context/SystemContext";
import FileUploader from "components/ui/FileUploader";
import { notify } from "util/Notify";
import moment from "moment";

const { Search } = Input;

export default function MediaSearch({ fileTypes, isMany, onSuccess }) {
    const ctx = useContext(SystemContext);
    const [loading, setLoading] = useState(false);
    const [list, setList] = useState([]);
    const [uploadList, setUploadList] = useState([]);
    const [runSearch, setSearch] = useState(0);
    const [mediaTypes, setMediaTypes] = useState([]);
    const [keyword, setKeyword] = useState("");
    const [sorted, setSorted] = useState({ id: "createdOn", desc: true });
    const [pagination, setPagination] = useState({ current: 1, pageSize: 20, total: 0, position: ["topRight", "bottomRight"], showSizeChanger: true });
    const [selectedKeys, setSelectedKeys] = useState([]);

    const [webList, setWebList] = useState([]);
    const [runWebSearch, setWebSearch] = useState(0);
    const [webKeyword, setWebKeyword] = useState("");
    const [webPagination, setWebPagination] = useState({ current: 1, pageSize: 24, total: 0 });
    const [orientation, setOrientation] = useState("");
    const [color, setColor] = useState("");

    useEffect(() => {
        setLoading(false);

        // structure the args
        let data = {
            keyword: keyword,
            mediaTypes: mediaTypes,
            orderBy: sorted,
            pagination: {
                pageNum: pagination.current,
                pageSize: pagination.pageSize,
            },
            portalId: ctx.portalId,
        };

        mediaService
            .get(data)
            .then((data) => {
                const pager = { ...pagination };
                pager.total = data.totalRecords;

                setList(data.media);
                setPagination(pager);
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setLoading(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [runSearch, mediaTypes]);

    //#region internal media search
    const handleTableChange = (paging, filters, sorter) => {
        let s = { ...sorted };
        const pager = { ...pagination };
        if (pager.pageSize !== paging.pageSize) {
            pager.pageSize = paging.pageSize;
            pager.current = 1;
        } else {
            pager.current = paging.current;
        }

        if (sorter.field) s = { id: sorter.field, desc: sorter.order === "descend" };

        setPagination(pager);
        setSorted(s);
        setSearch(runSearch + 1);
    };

    // row selection
    const handleSelection = (selectedRowKeys, selectedRows) => {
        let sel = selectedRowKeys.filter((i) => list.find((o) => o.id === i)); // make sure only selecting exising (non-spliced)

        // set keys to state
        setSelectedKeys(sel);
    };

    const handleMediaTypeChange = (ids) => {
        const pager = { ...pagination };
        pager.current = 1;
        setPagination(pager);
        setMediaTypes(ids);
    };

    const handleSubmit = (o) => {
        if (!onSuccess) return;

        // if one item only
        if (o) {
            onSuccess([o]);
            return;
        }

        // if multiples, select the full content data rows and pass them to parent
        onSuccess(list.filter((o) => selectedKeys.find((i) => i === o.id)));
        setSelectedKeys([]);
    };
    //#endregion

    //#region Upload
    const handleUploaded = (urls) => {
        let u = uploadList.concat(urls);
        setUploadList(u);
    };
    //#endregion

    //#region unsplash web search
    useEffect(() => {
        if (webKeyword === "") return;

        setLoading(true);

        // structure the args
        let data = {
            portalId: ctx.portalId,
            keyword: webKeyword,
            color: color,
            orientation: orientation,
            pagination: {
                pageNum: webPagination.current,
                pageSize: webPagination.pageSize,
            },
        };

        mediaService
            .getWebImages(data)
            .then((data) => {
                const pager = { ...webPagination };
                pager.total = data.totalRecords;

                // if it's a page 1, then replace, otherwise, add to the list
                setWebList(pager.current === 1 ? data.media : webList.concat(data.media));
                setWebPagination(pager);
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setLoading(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [runWebSearch]);

    // reset the current if any of the search values change
    useEffect(() => {
        const pager = { ...webPagination };
        pager.current = 1;
        setWebPagination(pager);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orientation, color, webKeyword]);

    const webSearch = () => {
        const pager = { ...webPagination };
        pager.current = 1;
        setWebPagination(pager);
        setWebSearch(runWebSearch + 1);
    };

    const webSearchMore = () => {
        const pager = { ...webPagination };
        pager.current = pager.current + 1;
        setWebPagination(pager);
        setWebSearch(runWebSearch + 1);
    };

    const handleSubmitWeb = (o) => {
        if (!onSuccess) return;

        // add the media to the db
        mediaService
            .post(o)
            .then((newId) => {
                o.id = newId; // affix the new id

                mediaService
                    .trackWebDownload(o.slug)
                    .then((data) => {
                        onSuccess([o]);
                    })
                    .catch((err) => notify.error(err));
            })
            .catch((err) => notify.error(err))
            .finally(() => setLoading(false));
    };
    //#endregion

    return (
        <Tabs>
            <Tabs.TabPane tab="Search" key="1">
                <Row gutter={[16, 16]} className="mb-3">
                    <Col xs={12}>
                        <Select mode="multiple" placeholder="Select Type" value={mediaTypes} onChange={handleMediaTypeChange} className="mr-2 w-40">
                            <Select.Option value={0}>Image</Select.Option>
                            <Select.Option value={1}>File</Select.Option>
                            <Select.Option value={2}>Video</Select.Option>
                            <Select.Option value={3}>Audio</Select.Option>
                            <Select.Option value={4}>Embedded</Select.Option>
                        </Select>
                    </Col>
                    <Col xs={12}>
                        <Search
                            id="keyword"
                            placeholder="search"
                            onSearch={() => setSearch(runSearch + 1)}
                            enterButton
                            className="w-100"
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                            loading={loading}
                        />
                    </Col>
                    {isMany && (
                        <Col xs={24}>
                            <Button type="primary" className="mr-2" disabled={selectedKeys.length === 0} onClick={() => handleSubmit()}>
                                Select
                            </Button>
                        </Col>
                    )}
                </Row>

                <Table
                    dataSource={list}
                    rowKey={(x) => x.id}
                    pagination={pagination}
                    loading={loading}
                    size="middle"
                    className="clear-both pointer"
                    onChange={handleTableChange}
                    rowSelection={
                        isMany && {
                            type: "checkbox",
                            onChange: handleSelection,
                            selectedRowKeys: selectedKeys,
                            hideSelectAll: true,
                        }
                    }
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: (e) => {
                                // only handle clicking on the row when single selecting
                                !isMany && handleSubmit(record);
                            },
                        };
                    }}
                    columns={[
                        {
                            title: "",
                            dataIndex: "thumbnail",
                            width: "6%",
                            responsive: ["lg"],
                            render: (text, record) => <img src={record.thumbnail} alt="Thumbnail" className="img-fluid avatar-50" />,
                        },
                        { title: "Title", dataIndex: "title", sorter: true },
                        {
                            title: "Type",
                            dataIndex: "lastName",
                            sorter: true,
                            render: (text, record) => (
                                <span>
                                    {record.mediaType === 0 && <Tag color="blue">Image</Tag>}
                                    {record.mediaType === 1 && <Tag color="gold">File</Tag>}
                                    {record.mediaType === 2 && <Tag color="cyan">Video</Tag>}
                                    {record.mediaType === 3 && <Tag color="magenta">Audio</Tag>}
                                    {record.mediaType === 4 && <Tag color="volcano">Embedded</Tag>}
                                </span>
                            ),
                        },
                        {
                            title: "Created",
                            dataIndex: "createdOn",
                            sorter: true,
                            responsive: ["xl"],
                            render: (text, record) => <span>{moment(record.createdOn).format("LL")}</span>,
                        },
                    ]}
                />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Upload" key="2">
                <FileUploader portalId={ctx.portalId} onUploaded={handleUploaded} multiple={true} showUploadList={false} addToMediaLibrary={true} />

                <Table
                    dataSource={uploadList}
                    rowKey={(x) => x.id}
                    pagination={false}
                    loading={loading}
                    size="middle"
                    className="clear-both pointer"
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: (e) => {
                                handleSubmit(record);
                            },
                        };
                    }}
                    columns={[
                        {
                            title: "",
                            dataIndex: "thumbnail",
                            width: "6%",
                            responsive: ["md"],
                            render: (text, record) => <img src={record.url} alt="Thumbnail" className="img-fluid" />,
                        },
                        { title: "Title", dataIndex: "title", sorter: true },
                        {
                            title: "Type",
                            dataIndex: "lastName",
                            sorter: true,
                            render: (text, record) => (
                                <span>
                                    {record.mediaType === 0 && <Tag color="blue">Image</Tag>}
                                    {record.mediaType === 1 && <Tag color="gold">File</Tag>}
                                    {record.mediaType === 2 && <Tag color="cyan">Video</Tag>}
                                    {record.mediaType === 3 && <Tag color="magenta">Audio</Tag>}
                                    {record.mediaType === 4 && <Tag color="volcano">Embedded</Tag>}
                                </span>
                            ),
                        },
                        {
                            title: "Created",
                            dataIndex: "createdOn",
                            sorter: true,
                            responsive: ["xl"],
                            render: (text, record) => <span>{moment(record.createdOn).format("LL")}</span>,
                        },
                    ]}
                />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Web" key="3">
                <Row gutter={[16, 16]} className="mb-3">
                    <Col xs={8}>
                        <Select value={orientation} onChange={(v) => setOrientation(v)} className="w-100">
                            <Select.Option value="">Any Orientation</Select.Option>
                            <Select.Option value="landscape">Landscape</Select.Option>
                            <Select.Option value="portrait">Portrait</Select.Option>
                            <Select.Option value="squarish">Squarish</Select.Option>
                        </Select>
                    </Col>
                    <Col xs={8}>
                        <Select value={color} onChange={(v) => setColor(v)} className="w-100">
                            <Select.Option value="">Any Color</Select.Option>
                            <Select.Option value="black_and_white">Black and White</Select.Option>
                            <Select.Option value="black">Black</Select.Option>
                            <Select.Option value="white">White</Select.Option>
                            <Select.Option value="yellow">Yellow</Select.Option>
                            <Select.Option value="orange">Orange</Select.Option>
                            <Select.Option value="red">Red</Select.Option>
                            <Select.Option value="purple">Purple</Select.Option>
                            <Select.Option value="magenta">Magenta</Select.Option>
                            <Select.Option value="green">Green</Select.Option>
                            <Select.Option value="teal">Teal</Select.Option>
                            <Select.Option value="blue">Blue</Select.Option>
                        </Select>
                    </Col>
                    <Col xs={8}>
                        <Search
                            id="webKeyword"
                            placeholder="search on Unsplash"
                            onSearch={webSearch}
                            enterButton
                            className="w-100"
                            value={webKeyword}
                            onChange={(e) => setWebKeyword(e.target.value)}
                            loading={loading}
                        />
                    </Col>
                </Row>

                <Row gutter={[16, 16]}>
                    {webList.map((o, i) => {
                        return (
                            <Col key={i} md={4} sm={8} xm={24} className="pointer card-hover" onClick={() => handleSubmitWeb(o)}>
                                <img src={o.thumbnail} alt={o.alt} className="img-fluid" />
                            </Col>
                        );
                    })}
                </Row>

                {pagination.current * pagination.pageSize < pagination.total && (
                    <div className="text-center mt-4 mb-5">
                        <Button type="primary" onClick={webSearchMore}>
                            Load More
                        </Button>
                    </div>
                )}
            </Tabs.TabPane>
        </Tabs>
    );
}
