import React, { useEffect, useState } from "react";
import { Form, Input, Button, Drawer, Table, Tag, Popconfirm } from "antd";
import { DeleteOutlined } from '@ant-design/icons';
import MediaSearch from './MediaSearch';

const MediaInput = ({
    slug,
    value,
    label,
    opts,
    form,
    onChange,
    media, // existing values
    debug, 

    values,
    className
}) => {
    const [ list, setList ] = useState(media || []);
    const [ showSearch, setShowSearch ] = useState(false);
    const isMany = opts.uploadLimit !== "1";

    if (!slug) {
        throw Error("MediaInput property: slug required");
    }

    if (!value && values && values[slug]) {
        value = values[slug];
    }

    
    // if we're resetting, empty the list
    useEffect(() => {
        if(media.length === 0)
            setList([]);
    }, [media]);
    

    const handleSuccess = (items) => {
        const s = list.concat(items);        
        setList(s);
        updateParent(s);
        setShowSearch(false);
    }

    const removeItem = (o) => {
        var idx = list.findIndex(i => i.id === o.id);
        if(idx > -1) {
            let items = [...list];
            items.splice(idx, 1);
            setList(items);
            updateParent(items);
        }
    }

    // notify the parents as to this change
    const updateParent = (items) => {
        const csv = items.map((o,i) => o.id).join(',');
        form.setFieldsValue({[slug]: csv});
        if(onChange)
            onChange(slug, csv);
    }

    return (
        <Form.Item
            label={label}
            className={className}
        >
            <div className="bordered p-2 text-center">
                {
                    (isMany || (!isMany && list.length === 0)) &&
                        <Button type="primary" className="my-2" onClick={() => setShowSearch(true)}>Select</Button>
                }
                
                {
                    list.length > 0 && 
                        <Table
                            dataSource={list}
                            rowKey={x => x.id}
                            pagination={false}
                            size="middle"
                            className="clear-both mt-3"
                            columns={[    
                                {
                                    title: '', dataIndex: 'url', 
                                    render: (text,record) => (
                                        <span>
                                            { record.mediaType === 0 && <img src={record.url} alt="Thumbnail" className="img-fluid avatar-50" /> }
                                            { record.mediaType === 1 && <img src={record.thumbnail} alt="Thumbnail" className="img-fluid avatar-50" /> }
                                            { record.mediaType === 2 && <></> }
                                            { record.mediaType === 3 && <></> }
                                            { record.mediaType === 4 && <></> }
                                        </span>       
                                        
                                    )
                                },                
                                { title: 'Title', dataIndex: 'title', 
                                    render: (text,record) => (
                                        <span>
                                            {record.title}
                                            {
                                                record.credit?.length > 0 && 
                                                    <div dangerouslySetInnerHTML={{ __html: record.credit }} className="credit" />                                                
                                            }                                            
                                        </span>                                    
                                    )
                                },
                                {
                                    title: 'Type', dataIndex: 'lastName', sorter: true,
                                    render: (text,record) => (
                                        <span>
                                            { record.mediaType === 0 && <Tag color="blue">Image</Tag> }
                                            { record.mediaType === 1 && <Tag color="gold">File</Tag> }
                                            { record.mediaType === 2 && <Tag color="cyan">Video</Tag> }
                                            { record.mediaType === 3 && <Tag color="magenta">Audio</Tag> }
                                            { record.mediaType === 4 && <Tag color="volcano">Embedded</Tag> }
                                        </span>                                    
                                    )
                                },
                                {
                                    title: '', width: '50px',
                                    render: (text, record) => (
                                        <Popconfirm
                                            title="Are you sure you want to delete this?"
                                            onConfirm={() => removeItem(record)}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button type="secondary" danger><DeleteOutlined /></Button>
                                        </Popconfirm>
                                    )
                                }
                            ]}
                        />
                }
                
            </div>

            <Form.Item name={slug}
                initialValue={value}
                hidden={!debug}>                
                <Input />
            </Form.Item>

            <Drawer 
                visible={showSearch}
                title="Search Media"
                height="85%"
                placement="bottom"
                onClose={() => setShowSearch(false)}
            >
                <MediaSearch
                    mediaTypes={opts.referenceTypes}
                    isMany={isMany}
                    onSuccess={handleSuccess} />                
            </Drawer>
        </Form.Item>
    );
};

export default MediaInput;