export const jsonHelpers = {
    hasJsonStructure,
    parseJson
} 

function hasJsonStructure (str) {
    if (typeof str !== 'string') return false;
    try {
        const result = JSON.parse(str);
        const type = Object.prototype.toString.call(result);
        return type === '[object Object]' 
            || type === '[object Array]';
    } catch (err) {
        return false;
    }
}

function parseJson (str) {
    return hasJsonStructure(str) ? JSON.parse(str) : JSON.parse("{}");
}