import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { roleService } from 'services/RoleService.js';
import { Form, Button, Card, Input } from "antd";
import { notify } from 'util/Notify';


export default function RoleInfo({ data }) {
    const [ form ] = Form.useForm();    
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    useEffect(() => {

        if(!data)
            return;

        // prepopulate the form
        form.setFieldsValue({
            name: data.name
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    

    const handleFinish = (values) => {

        // create the object
        if(data?.id) values.id = data.id;
        let canRedirect = false;
        
        setLoading(true);
        
        roleService.post(values)
            .then(data => {                                                
                // show a woo-hoo
                notify.success('Your role has been updated');                
                canRedirect = true;
            })
            .catch(err => {
                notify.error(err);       
            })
            .finally(() => {
                setLoading(false)
                // go back to the list
                if(canRedirect)
                    navigate("/manage/security/roles");
            }); 
    }


    return (
        <Card title="Role">

            <Form form={form} labelCol={{ span: 5 }} wrapperCol={{ span: 14 }} onFinish={handleFinish}>

                <Form.Item 
                    label="Role Name"
                    name="name"
                    rules={[{ required: false, message: 'Please give your role a name' }]}
                >
                    <Input placeholder="role name" />
                </Form.Item>

                <Form.Item wrapperCol={{ span: 12, offset: 5 }}>
                    <Button type="primary" htmlType="submit" loading={loading}>Submit</Button>
                </Form.Item>

            </Form>

        </Card>
    )
}