import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import { PlusOutlined, CheckCircleTwoTone, StopTwoTone } from "@ant-design/icons";
import { systemService } from "services/SystemService.js";
import { Table, Button, Card } from "antd";
import SystemContext from "context/SystemContext";

export default function Templates() {
    const ctx = useContext(SystemContext);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState();

    useEffect(() => {
        setLoading(true);

        systemService
            .getEmailTemplates(ctx.portalId)
            .then((data) => {
                setItems(data);
            })
            .catch((err) => console.log(err))
            .finally(() => setLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Card
            title={<IntlMessages id="manage.templates.title" />}
            extra={
                <Button type="primary" icon={<PlusOutlined />} color="btn btn-light" onClick={() => navigate(`/manage/templates/template`)} title="Add Template">
                    Add Template
                </Button>
            }
        >
            <div>
                <Table
                    dataSource={items}
                    rowKey={(x) => x.id}
                    loading={loading}
                    className="clear-both pointer"
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: (e) => {
                                navigate(`/manage/templates/template/${record.id}`);
                            },
                        };
                    }}
                    columns={[
                        { title: "Title", dataIndex: "name" },
                        { title: "Subject", dataIndex: "subject" },
                        {
                            title: "Active",
                            dataIndex: "isActive",
                            render: (text, record) => (
                                <span>
                                    {record.isActive && <CheckCircleTwoTone twoToneColor="#52c41a" />}
                                    {!record.isActive && <StopTwoTone twoToneColor="#aa3333" />}
                                </span>
                            ),
                        },
                    ]}
                />
            </div>
        </Card>
    );
}
