import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Input, Select, Radio, Form, Switch } from "antd";
import { systemService } from "services/SystemService.js";
import ListOptionEditor from "./ListOptionEditor";
// import { contentService } from 'services/ContentService.js';
import SystemContext from "context/SystemContext";
import { notify } from "util/Notify";

/*
    This is a special component.  It handles anything in the system that allows for the creation 
    of custom properties.  

    Profile Props
    Form Questions (doesn't save or retrieve directly, but instead passing to/from parent)
    Content Type Customizations
*/

export default function SystemPropertyEditor({ selected, entityId, propertyType, onFinish, useAnswerKey = false, layout = "horizontal" }) {
    const ctx = useContext(SystemContext);
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    // const [options, setOptions] = useState({});
    const [fieldTypes, setFieldTypes] = useState([]);
    const [selectedFormat, setSelectedFormat] = useState(0);
    const [isAnything, setAnything] = useState(false);
    const [isText, setText] = useState(false);
    const [isList, setList] = useState(false);
    const [isStaticList, setStaticList] = useState(false);
    const [isNumeric, setNumeric] = useState(false);
    const [isCurrency, setCurrency] = useState(false);
    const [isDate, setDate] = useState(false);
    const [isPhone, setPhone] = useState(false);
    const [isYesNo, setYesNo] = useState(false);
    const [isUpload, setUpload] = useState(false);
    const [isReference, setReference] = useState(false);
    const [isCorrect, setCorrect] = useState(false);
    const [listOptions, setListOptions] = useState([]);
    const styleNumber = [
        { label: "Plain Number", value: "plain" },
        { label: "Slider", value: "slider" },
    ];
    const stylePhone = [
        { label: "Any Format", value: "any" },
        { label: "US/CAN", value: "uscan" },
    ];
    const styleYesNo = [
        { label: "Radio Buttons", value: "radio" },
        { label: "Switch", value: "switch" },
    ];
    const styleList = [
        { label: "Single Select", value: "select" },
        { label: "Checkboxes", value: "checkboxes" },
        { label: "Radios", value: "radios" },
    ];
    const [listSource, setListSource] = useState([]);

    useEffect(() => {
        setLoading(true);

        Promise.all([systemService.getFieldTypes(), systemService.getSystemLists()])
            .then((data) => {
                // form the entire list of list sources
                let ls = [{ label: "Static List", value: "static list" }];
                // const ctList = data[1].map((o,i) => { return { label: `${o.name} (${o.quantity})`, value: `contenttype_${o.id}` } });
                const sysList = data[1].map((o, i) => {
                    return { label: `${o.name} (${o.quantity})`, value: `list_${o.id}` };
                });
                ls = ls.concat(sysList);

                setFieldTypes(data[0]);
                setListSource(ls);
                getExistingItem();
            })
            .catch((err) => notify.error(err))
            .finally(() => setLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        populateFields(selected);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected]);

    // load up existing item potentially
    const getExistingItem = () => {
        // skip this if in forms (manual loading)
        if (selected) return;

        // if id doesn't exist
        if (id === undefined) {
            form.setFieldsValue({ listOptions: "" });
            return;
        }

        setLoading(true);

        // load up the data
        systemService
            .getSystemPropertyById(id)
            .then((data) => {
                populateFields(data);
            })
            .catch((err) => notify.error(err))
            .finally(() => setLoading(false));
    };

    // loads up form fields from either a query or passed in data
    const populateFields = (data) => {
        if (!data) return;
        var options = data.options && data.options !== "" ? JSON.parse(data.options) : {};

        form.setFieldsValue({
            label: data.label,
            slug: data.slug,
            fieldType: data.fieldType,
            isRequired: data.isRequired,
            permissionType: data.permissionType,
            fieldStyle: options?.fieldStyle ?? "",
            listSource: options?.listSource ?? "",
            correct: options?.correct ?? "",
            maxLength: options?.maxLength ?? "",
            decimals: options?.decimals ?? "",
            minNumber: options?.minNumber ?? "",
            maxNumber: options?.maxNumber ?? "",
            uploadLimit: options?.uploadLimit ?? "",
            fileTypes: options?.fileTypes ?? "",
            dateFormat: options?.dateFormat ?? "",
            timeFormat: options?.timeFormat ?? "",
            placeholder: options?.placeholder ?? "",
            referenceAmount: options?.referenceAmount ?? "",
            referenceTypes: options?.referenceTypes ?? [],
            validationExpression: options?.validationExpression ?? "",
            listOptions:
                options?.listOptions?.reduce((p, c) => {
                    return p.label ? p.label + "|" + p.value + "\n" + c.label + "|" + c.value : p + "\n" + c.label + "|" + c.value;
                }, "") ?? "",
        });

        setListOptions(options?.listOptions);

        handleFieldType();
    };

    const handleFieldType = () => {
        const ft = form.getFieldValue("fieldType");
        const ls = form.getFieldValue("listSource");

        setSelectedFormat(ft);
        setAnything(true);
        setText(ft === 0 || ft === 1 || ft === 2 || ft === 3 || ft === 4 || ft === 5 || ft === 6);
        setList(ft === 16);
        setStaticList(ft === 16 && ls === "static list");
        setNumeric(ft === 10);
        setCurrency(ft === 11);
        setDate(ft === 7 || ft === 8 || ft === 9);
        setPhone(ft === 3);
        setYesNo(ft === 15);
        setUpload(ft === 12);
        setReference(ft === 13);
        setCorrect(ft === 16 && useAnswerKey);

        /* why is this here?
        form.setFieldsValue({
            fieldStyle: options.fieldStyle ?? ""
        });        
        */
    };

    const ensureSlug = () => {
        let label = form.getFieldValue("label") ?? "";
        let slug = form.getFieldValue("slug") ?? "";
        label = label
            .trim()
            .toLowerCase()
            .replace(/ /g, "-")
            .replace(/[^a-z0-9_-]/g, "");
        slug = slug
            .trim()
            .toLowerCase()
            .replace(/ /g, "-")
            .replace(/[^a-z0-9_-]/g, "");

        form.setFieldsValue({
            slug: slug.length > 0 ? slug : label,
        });
    };

    const handleSave = (values) => {
        setLoading(true);

        // use the id of the URL only if this isn't a form
        if (id && !selected) values.id = id;

        if (entityId) {
            values.propertyType = propertyType;
            values.entityId = entityId;
        }

        // create the options
        let options = {};

        if (values.isCustomValidation) options.isCustomValidation = true;

        if (values.fieldStyle !== "") options.fieldStyle = values.fieldStyle;

        if (values.listSource !== "") options.listSource = values.listSource;

        if (listOptions !== "")
            // special
            options.listOptions = listOptions;

        if (values.correct !== "") options.correct = values.correct;

        if (values.maxLength > 0) options.maxLength = values.maxLength;

        if (values.decimals !== "") options.decimals = values.decimals;

        if (values.minNumber !== "") options.minNumber = parseInt(values.minNumber, 10);

        if (values.maxNumber !== "") options.maxNumber = parseInt(values.maxNumber, 10);

        if (values.uploadLimit !== "") options.uploadLimit = values.uploadLimit;

        if (values.fileTypes !== "") options.fileTypes = values.fileTypes;

        if (values.dateFormat !== "") options.dateFormat = values.dateFormat;

        if (values.timeFormat !== "") options.timeFormat = values.timeFormat;

        if (values.placeholder !== "") options.placeholder = values.placeholder;

        if (values.referenceAmount !== "") options.referenceAmount = values.referenceAmount;

        if (values.referenceTypes?.length > 0) options.referenceTypes = values.referenceTypes;

        if (values.validationExpression !== "") options.validationExpression = values.validationExpression;

        values.options = JSON.stringify(options);

        values.portalId = ctx.portalId;

        if (!values.permissionType) values.permissionType = 0; // everyone

        switch (values.fieldType) {
            case 0:
                values.fieldTypeText = "Plain Text";
                break;
            case 1:
                values.fieldTypeText = "Email";
                break;
            case 2:
                values.fieldTypeText = "Website URL";
                break;
            case 3:
                values.fieldTypeText = "Phone Number";
                break;
            case 4:
                values.fieldTypeText = "Password";
                break;
            case 5:
                values.fieldTypeText = "Basic Paragraph";
                break;
            case 6:
                values.fieldTypeText = "Wysiwyg Editor";
                break;
            case 7:
                values.fieldTypeText = "Date/Time Picker";
                break;
            case 8:
                values.fieldTypeText = "Date Picker";
                break;
            case 9:
                values.fieldTypeText = "Time Picker";
                break;
            case 10:
                values.fieldTypeText = "Number";
                break;
            case 11:
                values.fieldTypeText = "Currency";
                break;
            case 12:
                values.fieldTypeText = "Image Picker";
                break;
            case 13:
                values.fieldTypeText = "Reference";
                break;
            case 15:
                values.fieldTypeText = "Yes/No";
                break;
            case 16:
                values.fieldTypeText = "List";
                break;
            case 17:
                values.fieldTypeText = "Form";
                break;
            default:
                values.fieldTypeText = "";
                break;
        }

        // hijack this and send to the parent if so needed
        if (onFinish) {
            setLoading(false);
            onFinish(values);
            return;
        }

        systemService
            .postSystemProperty(values)
            .then((data) => {
                notify.success("You have successfully updated the property");
                setLoading(false);
                // leave
                handleRedirect();
            })
            .catch((err) => console.log(err));
    };

    const handleRedirect = () => {
        let returnUrl = "";

        switch (propertyType) {
            case 1: // contenttype props
                returnUrl = `/manage/cms/contenttype/${entityId}#props`;
                break;
            case 2:
                returnUrl = `/manage/forms/form/${entityId}#editor`;
                break;
            default: // user props
                returnUrl = "/manage/profileprops";
                break;
        }
        navigate(returnUrl);
    };

    return (
        <Form form={form} labelCol={{ span: layout === "horizontal" ? 6 : 24 }} wrapperCol={{ span: layout === "horizontal" ? 18 : 24 }} onFinish={handleSave} layout={layout}>
            <Form.Item
                name="label"
                label="Label"
                onBlur={ensureSlug}
                rules={[
                    {
                        required: true,
                        message: "Please enter a label",
                    },
                ]}
            >
                <Input placeholder="enter a label" />
            </Form.Item>

            <Form.Item name="slug" label="Slug">
                <Input placeholder="slug" onBlur={ensureSlug} />
            </Form.Item>

            <Form.Item name="fieldType" label="Field Type">
                <Select onChange={handleFieldType}>
                    {fieldTypes.map((o, i) => {
                        return (
                            <Select.Option key={o.key} value={o.key}>
                                {o.value}
                            </Select.Option>
                        );
                    })}
                </Select>
            </Form.Item>

            <Form.Item name="fieldStyle" label="Field Style" hidden={!isList && !isNumeric && !isPhone && !isYesNo}>
                <Select>
                    {selectedFormat === 10 &&
                        styleNumber.map((o, i) => {
                            return (
                                <Select.Option key={`style2${o.value}`} value={o.value}>
                                    {o.label}
                                </Select.Option>
                            );
                        })}
                    {selectedFormat === 3 &&
                        stylePhone.map((o, i) => {
                            return (
                                <Select.Option key={`style3${o.value}`} value={o.value}>
                                    {o.label}
                                </Select.Option>
                            );
                        })}
                    {selectedFormat === 15 &&
                        styleYesNo.map((o, i) => {
                            return (
                                <Select.Option key={`style15${o.value}`} value={o.value}>
                                    {o.label}
                                </Select.Option>
                            );
                        })}
                    {selectedFormat === 16 &&
                        styleList.map((o, i) => {
                            return (
                                <Select.Option key={`style16${o.value}`} value={o.value}>
                                    {o.label}
                                </Select.Option>
                            );
                        })}
                </Select>
            </Form.Item>

            <Form.Item name="listSource" label="List Source" hidden={!isList}>
                <Select onChange={handleFieldType}>
                    {listSource.map((o, i) => {
                        return (
                            <Select.Option key={o.value} value={o.value}>
                                {o.label}
                            </Select.Option>
                        );
                    })}
                </Select>
            </Form.Item>

            <Form.Item label="List Options" hidden={!isStaticList}>
                <ListOptionEditor list={listOptions} onChange={(values) => setListOptions(values)} hideValues={true} />
            </Form.Item>

            <Form.Item name="correct" label="Correct Answer" hidden={!isCorrect && !isStaticList}>
                <Select>
                    {listOptions &&
                        listOptions.map((o, i) => {
                            return (
                                <Select.Option key={o.value} value={o.value}>
                                    {o.label}
                                </Select.Option>
                            );
                        })}
                </Select>
            </Form.Item>

            <Form.Item hidden={!isAnything} name="isRequired" label="Required" valuePropName="checked">
                <Switch />
            </Form.Item>

            <Form.Item name="maxLength" label="Max Length" hidden={!isText}>
                <Input type="number" />
            </Form.Item>

            <Form.Item name="decimals" label="Decimals" hidden={!isNumeric && !isCurrency}>
                <Input type="number" />
            </Form.Item>

            <Form.Item name="minNumber" label="Minimum Number" hidden={!isNumeric && !isCurrency}>
                <Input type="number" />
            </Form.Item>

            <Form.Item name="maxNumber" label="Maximum Number" hidden={!isNumeric && !isCurrency}>
                <Input type="number" />
            </Form.Item>

            <Form.Item name="uploadLimit" label="Upload Limit" hidden={!isUpload}>
                <Radio.Group
                    options={[
                        { label: "One", value: "1" },
                        { label: "Many", value: "many" },
                    ]}
                    optionType="button"
                    buttonStyle="solid"
                />
            </Form.Item>

            <Form.Item name="fileTypes" label="File Types" hidden={!isUpload}>
                <Select>
                    <Select.Option value="images">Images</Select.Option>
                    <Select.Option value="videos">Videos</Select.Option>
                    <Select.Option value="text">Text</Select.Option>
                    <Select.Option value="audio">Audio</Select.Option>
                    <Select.Option value="any">Most Anything</Select.Option>
                </Select>
            </Form.Item>

            <Form.Item name="dateFormat" label="Date Format" hidden={!isDate}>
                <Select>
                    <Select.Option value="MM/dd/yyyy"></Select.Option>
                    <Select.Option value="MM-dd-yyyy"></Select.Option>
                    <Select.Option value="MM.dd.yyyy"></Select.Option>
                    <Select.Option value="yyyy/MM/dd"></Select.Option>
                    <Select.Option value="yyyy-MM-dd"></Select.Option>
                    <Select.Option value="dd/MM/yyyy"></Select.Option>
                    <Select.Option value="dd-MM-yyyy"></Select.Option>
                    <Select.Option value="MMMM dd, yyyy"></Select.Option>
                </Select>
            </Form.Item>

            <Form.Item name="timeFormat" label="Time Format" hidden={!isDate}>
                <Select>
                    <Select.Option value="h:mm tt"></Select.Option>
                    <Select.Option value="hh:mm tt"></Select.Option>
                    <Select.Option value="H:mm"></Select.Option>
                    <Select.Option value="HH:mm"></Select.Option>
                    <Select.Option value="h:mm:ss tt"></Select.Option>
                    <Select.Option value="H:mm:ss"></Select.Option>
                </Select>
            </Form.Item>

            <Form.Item name="referenceAmount" label="References" hidden={!isReference}>
                <Radio.Group
                    options={[
                        { label: "One", value: "1" },
                        { label: "Many", value: "many" },
                    ]}
                    optionType="button"
                    buttonStyle="solid"
                />
            </Form.Item>

            <Form.Item name="placeholder" label="Placeholder" hidden={!isText}>
                <Input />
            </Form.Item>

            <Form.Item hidden={!isText} name="isValidated" label="Use Custom Validation" valuePropName="checked">
                <Switch />
            </Form.Item>

            <Form.Item name="validationExpression" label="Custom Validation RegEx" hidden={!isText}>
                <Input />
            </Form.Item>

            <Form.Item name="permissionType" label="Permission" hidden={propertyType > 0}>
                <Radio.Group optionType="button" buttonStyle="solid">
                    <Radio.Button value={0}>Everyone</Radio.Button>
                    <Radio.Button value={1}>Private (User Only)</Radio.Button>
                    <Radio.Button value={2}>Admin Only</Radio.Button>
                </Radio.Group>
            </Form.Item>

            <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading} disabled={!isAnything} block={layout === "vertical"}>
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
}
