import axios from "./AxiosHelper";

// define public methods
export const userService = {
    get,
    getById,
    getSelf,
    post,
    getUserRoles,
    addRoleToUser,
    deleteRoleFromUser,
    updatePassword,
    getProperties,
    postProperties,
    deleteUser,
    restoreUser,
    updateSwitchedApp,

    getSubscription,
    getSubscriptionPayments,
    getSubscriptionUsers,
    getApps,
    getApp,
    postApp,
    getSubscriptionTypes,
    postSubscription,
};

function get(page, pagesize, sorted, keyword, roleid) {
    let url = `/api/user?page=${page}&pagesize=${pagesize}`;

    if (keyword && keyword.length > 0) url += "&keyword=" + escape(keyword);

    if (roleid && roleid.length > 0) url += "&roleid=" + roleid;

    if (sorted && sorted.id) url += "&sort=" + sorted.id + "&desc=" + sorted.desc;

    return axios.getData(url, true);
}

function getById(id) {
    return axios.getData(`/api/user/${id}`, true);
}

function getSelf() {
    return axios.getData(`/api/user/me`, true);
}

function post(data) {
    return axios.upsertData(data.id, `/api/user`, data, true);
}

function getUserRoles(id) {
    return axios.getData(`/api/user/${id}/roles`, true);
}

function addRoleToUser(userId, roleId, startDate, expiryDate) {
    let data = {
        userId,
        roleId,
        startDate,
        expiryDate,
    };

    return axios.postData(`/api/user/${userId}/role`, data, true);
}

function deleteRoleFromUser(userId, roleId) {
    return axios.deleteData(`/api/user/${userId}/role/${roleId}`);
}

function updatePassword(values) {
    return axios.putData(`/api/user/${values.id}/password`, values, true);
}

function getProperties(id) {
    return axios.getData(`/api/user/${id}/properties`, true);
}

function postProperties(id, values) {
    return axios.postData(`/api/user/${id}/properties`, values, true);
}

function deleteUser(id) {
    return axios.deleteData(`/api/user/${id}`);
}

function restoreUser(id) {
    return axios.putData(`/api/user/${id}/restore`, null, true);
}

function updateSwitchedApp(id, portalId) {
    return axios.putData(`/api/user/${id}/switchapp/${portalId}`, null, true);
}

function getSubscription(id) {
    return axios.getData(`/api/user/subscription/${id}`, true);
}

function getSubscriptionPayments(id) {
    return axios.getData(`/api/user/subscription/${id}/payments`, true);
}

function getSubscriptionUsers(id) {
    return axios.getData(`/api/user/subscription/${id}/users`, true);
}

function getApps(id) {
    return axios.getData(`/api/user/subscription/${id}/apps`, true);
}

function getApp(id) {
    return axios.getData(`/api/user/subscription/apps/${id}`, true);
}

function postApp(values) {
    return axios.upsertData(values.id, `/api/user/subscription/app`, values, true);
}

function getSubscriptionTypes() {
    return axios.getData(`/api/user/subscription/types`, false);
}

function postSubscription(values) {
    return axios.upsertData(values.id, `/api/user/subscription`, values, true);
}
