import axios from './AxiosHelper';

// define public methods
export const mediaService = {
    get,
    getByIdOrSlug,
    post,
    deleteMedia,
    deleteMediaList,

    getWebImages,
    trackWebDownload
};

//#region Content
/*
    args:
        keyword: "",
        mediaTypes: [],
        orderBy: {id: sorter.field, desc: sorter.order} // (i.e. {createdOn, desc}, {title, asc}),
        pagination: { pageNum, pageSize }

*/
function get(data) {
    return axios.postData(`/api/media/search`, data, true);
}

function getByIdOrSlug(idOrSlug) {
    return axios.getData(`/api/media/${idOrSlug}`, true);    
}

function post(data) {
    return axios.upsertData(data.id, `/api/media`, data, true);
}

function deleteMedia(id) {
    return axios.deleteData(`/api/media/${id}`, true);
}

function deleteMediaList(ids) {
    return axios.deleteMultipleData(`/api/media`, ids);
}

// unsplash web api
function getWebImages(data) {
    return axios.postData(`/api/media/websearch`, data, true);
}
function trackWebDownload(id) {
    return axios.getData(`/api/media/websearch/${id}`, true);
}