import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { roleService } from "services/RoleService.js";
import { Button, Card, Spin } from "antd";
import RoleProfile from "./components/RoleProfile";

export default function Role() {
    const navigate = useNavigate();
    const { id = 0 } = useParams();
    const [loading, setLoading] = useState(true);
    const [item, setItem] = useState();

    useEffect(() => {
        // if id doesn't exist
        if (!id) {
            setLoading(false);
            return;
        }

        // get existing
        roleService
            .getById(id)
            .then((data) => {
                setItem(data);
            })
            .catch((err) => {
                console.log(err);
                navigate("/manage/security/roles");
            })
            .finally(() => setLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (loading) {
        return (
            <Card title="Role">
                <Spin size="large" />
            </Card>
        );
    } else {
        return (
            <Card
                title="Role"
                extra={
                    <span>
                        <Button color="btn btn-light" onClick={() => navigate("/manage/security/roles")} title="View All Roles">
                            View All Roles
                        </Button>
                    </span>
                }
            >
                <RoleProfile data={item} />
            </Card>
        );
    }
}
