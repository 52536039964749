import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Form, Input, Button, Radio, Select } from "antd";
import { credentialService } from "services/CredentialService";
import { systemService } from "services/SystemService";
import { notify } from "util/Notify";

export default function Credential() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [form] = Form.useForm();
    const [agencies, setAgencies] = useState([]);
    const [renewalTypes, setRenewalTypes] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        var args = {
            keyword: "",
            orderBy: { id: "name", desc: false },
            pagination: {
                pageNum: 1,
                pageSize: 10000,
            },
        };

        // get renewal types from enum
        systemService
            .getRenewalTypes()
            .then((data) => {
                setRenewalTypes(data);
            })
            .catch((err) => console.log(err));

        // get list of agencies
        credentialService
            .searchAgencies(args)
            .then((data) => {
                setAgencies(data.items);
            })
            .catch((err) => console.log(err));
    }, []);

    // onload
    useEffect(() => {
        async function getItem(id) {
            // get this list and all the content types
            if (id) {
                const i = await credentialService.getCredential(id);

                // set form fields
                form.setFieldsValue({ ...i });
            }
        }

        // load up the initial data, but not until the agencies list has been created
        if (agencies.length > 0) getItem(id);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, agencies]);

    // save
    const onSave = async (values) => {
        setLoading(true);

        values.id = parseInt(id, 10) || 0;

        await credentialService
            .saveCredential(values)
            .then((data) => {
                notify.success(`Credential was saved`, () => navigate(`/manage/credentials/list`));
            })
            .catch((err) => {
                notify.error(err);
            })
            .finally(() => setLoading(false));
    };

    return (
        <Card
            title="Manage Credential"
            extra={
                <Button color="btn btn-light" onClick={() => navigate("/manage/credentials/list")} title="All Credentials">
                    View All Credentials
                </Button>
            }
        >
            <Form name="mainForm" form={form} layout="vertical" onFinish={onSave}>
                <Form.Item name="credentialAgencyId" label="Agency" rules={[{ required: true, message: "required" }]}>
                    <Select placeholder="Select Status" showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                        <Select.Option value="">Any Agency</Select.Option>
                        {agencies.map((o, i) => {
                            return (
                                <Select.Option key={i} value={o.id}>
                                    {o.name} ({o.status})
                                </Select.Option>
                            );
                        })}
                    </Select>
                </Form.Item>

                <Form.Item name="name" label="Name" rules={[{ required: true, message: "required" }]}>
                    <Input />
                </Form.Item>

                <Form.Item name="status" label="Status">
                    <Radio.Group>
                        <Radio value={true}>Active</Radio>
                        <Radio value={false}>Disabled</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item name="renewalType" label="Renewal Type">
                    <Select placeholder="Select Renewal">
                        {renewalTypes.map((o, i) => {
                            return (
                                <Select.Option key={o.key} value={o.key}>
                                    {o.value}
                                </Select.Option>
                            );
                        })}
                    </Select>
                </Form.Item>

                <Form.Item name="description" label="Description">
                    <Input.TextArea rows={4} />
                </Form.Item>

                <Form.Item name="externalId" label="External Id" disabled={true}>
                    <Input disabled={true} />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    );
}
