import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Layout from 'components/MasterLayout';
import Media from './media';

export default function MediaLibrary () {

    return (
        <Layout>
            <Routes>
                <Route path="media" element={<Media />} />
                <Route path="*" element={<Media />} />
            </Routes>
        </Layout>
    );
};
