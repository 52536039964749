import React from "react";
import { Modal } from "antd";
import IntlMessages from "util/IntlMessages";
import FileUploader from "components/ui/FileUploader";
import MediaLibraryContext from "../context/MediaLibraryContext";
import MediaInfo from "./MediaInfo";

class MediaModal extends React.Component {
    static contextType = MediaLibraryContext;

    render() {
        return (
            <div>
                <Modal title={<IntlMessages id="manage.media.modal.title" />} visible={this.context.modal} width="90%" onCancel={this.context.toggleModal} getContainer={false} footer={false}>
                    <FileUploader addToMediaLibrary={true} multiple={true} onUploaded={this.context.handleSuccess} portalId={this.context.portalId} />

                    <MediaInfo />
                </Modal>
            </div>
        );
    }
}

export default MediaModal;
