import axios from "./AxiosHelper";

// define public methods
export const credentialService = {
    searchAgencies,
    getAgency,
    saveAgency,
    deleteAgency,
    emailAgencyReviewers,

    searchCredentials,
    getCredential,
    saveCredential,
    deleteCredential,

    searchVerifications,
    getVerification,
    saveVerification,
    deleteVerification,
};

//#region Agencies
function searchAgencies(data) {
    return axios.postData(`/api/credential/agencies/search`, data, true);
}

function getAgency(id) {
    return axios.getData(`/api/credential/agencies/${id}`, true);
}

function saveAgency(data) {
    return axios.upsertData(data.id, `/api/credential/agencies`, data, true);
}

function deleteAgency(id) {
    return axios.deleteData(`/api/credential/agencies/${id}`);
}

function emailAgencyReviewers(id) {
    return axios.postData(`/api/credential/agencies/${id}/emailreviewers`, null, true);
}
//#endregion

//#region credentials certificates
function searchCredentials(data) {
    return axios.postData(`/api/credential/certifications/search`, data, true);
}

function getCredential(id) {
    return axios.getData(`/api/credential/certifications/${id}`, true);
}

function saveCredential(data) {
    return axios.upsertData(data.id, `/api/credential/certifications`, data, true);
}

function deleteCredential(id) {
    return axios.deleteData(`/api/credential/certifications/${id}`);
}
//#endregion

//#region verifications
function searchVerifications(data) {
    return axios.postData(`/api/credential/verifications/search`, data, true);
}

function getVerification(id) {
    return axios.getData(`/api/credential/verifications/${id}`, true);
}

function saveVerification(data) {
    return axios.upsertData(data.id, `/api/credential/verifications`, data, true);
}

function deleteVerification(id) {
    return axios.deleteData(`/api/credential/verifications/${id}`);
}
//#endregion
