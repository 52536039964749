import React from "react";
import { Tabs, Row, Col } from "antd";
import RoleInfo from "./RoleInfo";
import RoleClaims from "./RoleClaims";

const { TabPane } = Tabs;

export default function RoleProfile({ data }) {
    return (
        <div>
            <Row>
                <Col span={24}>
                    <Tabs>
                        <TabPane tab="Role Info" key="1">
                            <RoleInfo data={data} />
                        </TabPane>

                        <TabPane tab="Permissions" key="2" disabled={!data?.id}>
                            <RoleClaims data={data} />
                        </TabPane>
                    </Tabs>
                </Col>
            </Row>
        </div>
    );
}
