import React, { useEffect, useState } from "react";
import { Form, Select, Checkbox, Radio } from "antd";
import { systemService } from 'services/SystemService';

const ListInput = ({
    slug,
    value,
    label,
    opts,
    isRequired,

    values,
    error,
    errors,
    className,
    placeholder,
    disabled
}) => {
    const [ list, setList ] = useState([]);
    const [ loaded, setLoaded ] = useState(false);
    if (!slug) {
        throw Error("ListInput property: slug required");
    }

    if (!value && values && values[slug]) {
        value = values[slug];
    }
    if (!error && errors && errors[slug]) {
        error = errors[slug];
    }


    useEffect(() => {        
        // static list
        if(opts.listSource === "static list") {
            setList(opts.listOptions);
            setLoaded(true);
        }
        // list options from a system list
        else if(opts.listSource.indexOf("list_") > -1) {
            let listId = opts.listSource.split("_")[1];       
        
            systemService.getSystemList(listId)
                .then((data) => {            
                    let l = data.items.map((o) => { return { label: o.label, value: o.value }; });            
                    // console.log(listId, l);
                    setList(l);
                    setLoaded(true);
                })
                .catch(() => {});        
        }        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [opts.listSource]);

    return (
        <Form.Item
            label={label}
            className={className}
        >
            {
                opts.fieldStyle === "select" && 
                    <Form.Item name={slug}
                        initialValue={value}
                        rules={[
                            { required: isRequired, message: 'Required' }
                        ]}>
                        <Select
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>                                
                                option.children && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            <Select.Option>{placeholder}</Select.Option>
                            
                            {
                                list.map((o,i) => {
                                    return <Select.Option key={o.value}>{o.label}</Select.Option>
                                })
                            }
                        </Select>
                    </Form.Item>
            }

            {
                loaded && opts.fieldStyle === "checkboxes" && 
                    <Form.Item name={slug} 
                        initialValue={value}
                        rules={[
                            { required: isRequired, message: 'Required' }
                        ]}>
                        <Checkbox.Group options={list} />              
                    </Form.Item>
            }

            {
                loaded && opts.fieldStyle === "radios" && 
                    <Form.Item name={slug} 
                        initialValue={value}
                        className="column-vertical"
                        rules={[
                            { required: isRequired, message: 'Required' }
                        ]}>
                        <Radio.Group options={list} />              
                    </Form.Item>
            }

        </Form.Item>
    );
};

export default ListInput;