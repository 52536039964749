import React from 'react';
import SystemContext from 'context/SystemContext';
import UserLeads from './components/Leads';

class Leads extends React.Component {
    
    render() {        
        return (
            <UserLeads data={this.context.getUser()} isAdmin={false} />                
        );
    }
}

Leads.contextType = SystemContext;
export default Leads;