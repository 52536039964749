import React from "react";
import { Button, Card, Form, Input, Switch } from "antd";
// import { contactService } from 'services/ContactService';

class LeadEdit extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
        };
    }

    componentDidMount() {
        // update - get the data for this id
        if (this.props.id !== 0) {
            this.setState({
                loading: true,
            });

            /*
            contactService.getByIdByList(this.props.id, this.props.ownerId, null)
                .then(data => {                    

                    this.props.form.setFieldsValue({
                        firstName: data.firstName,
                        lastName: data.lastName,
                        email: data.email,
                        isSubscribed: data.isSubscribed
                    });
                })
                .catch(err => console.log(err))
                .finally(() => {
                    this.setState({
                        loading: false
                    })
                });
                */
        } else {
            this.props.form.setFieldsValue({
                isSubscribed: true,
            });
        }
    }

    handleSubmit = (values) => {
        // append the object
        values.id = this.props.id;
        values.userId = this.props.userId;

        this.setState({
            loading: true,
        });

        /*
        contactService
            .post(values)
            .then((data) => {
                if (this.props.onSubmit) this.props.onSubmit();
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
            */
    };

    render() {
        return (
            <Card
                title="Lead"
                extra={
                    <Button type="primary" onClick={() => this.props.history.goBack()}>
                        Back
                    </Button>
                }
            >
                <Form labelCol={{ span: 5 }} wrapperCol={{ span: 14 }} onFinish={this.handleSubmit}>
                    <Form.Item label="First Name" name="firstName" rules={[{ required: true, message: "Please enter a first name" }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item label="Last Name" name="lastName" rules={[{ required: true, message: "Please enter a last name" }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item label="Email" name="email" rules={[{ required: true, message: "Please enter an email address" }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item label="Subscribed" name="isSubscribed" valuePropName="checked">
                        <Switch />
                    </Form.Item>

                    <Form.Item wrapperCol={{ span: 12, offset: 5 }}>
                        <Button type="primary" htmlType="submit" loading={this.state.loading}>
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        );
    }
}

export default LeadEdit;
