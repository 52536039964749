import axios from './AxiosHelper';

// define public methods
export const eventLogService = {    
    search,
    deleteMany,
    getTypes
};

//#region Crud
function search(data) {
    return axios.postData(`/api/eventlog/search`, data, true);
}

function deleteMany(ids) {
    return axios.deleteMultipleData(`/api/eventlog`, ids);
}

function getTypes() {
    return axios.getData(`/api/eventlog/types`, true);
}
//#endregion
