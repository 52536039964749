import React, { useEffect, useState } from "react";
import { Form, Select } from "antd";
import { formService } from "services/FormService";

const FormInput = ({
    slug,
    value,
    label,
    opts,

    values,
    error,
    errors,
    className,
    placeholder,
    disabled,
}) => {
    const [list, setList] = useState([]);

    if (!slug) {
        throw Error("ListInput property: slug required");
    }

    if (!value && values && values[slug]) {
        value = values[slug];
    }
    if (!error && errors && errors[slug]) {
        error = errors[slug];
    }

    useEffect(() => {
        formService
            .get()
            .then((data) => {
                let l = data.map((o) => {
                    return { label: o.name, value: o.id };
                });
                // console.log(listId, l);
                setList(l);
            })
            .catch(() => {});

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Form.Item label={label} className={className} name={slug} initialValue={value}>
            <Select showSearch optionFilterProp="children" filterOption={(input, option) => option.children && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                <Select.Option>{placeholder}</Select.Option>

                {list.map((o, i) => {
                    return <Select.Option key={o.value}>{o.label}</Select.Option>;
                })}
            </Select>
        </Form.Item>
    );
};

export default FormInput;
