import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DeleteOutlined } from "@ant-design/icons";
import { Tabs, Row, Col, Card, Button, Popconfirm } from "antd";
import IntlMessages from "util/IntlMessages";
import UserProfileInfo from "./UserProfileInfo";
import UserPassword from "./UserPassword";
import UserRoles from "./UserRoles";
import UserProperties from "./UserProperties";
import UserSettingsContext from "../context/UserSettingsContext";
import moment from "moment";
import { authenticationService } from "services/AuthenticationService";
import { notify } from "util/Notify";

const { TabPane } = Tabs;

/*
    if userId comes via props, then it's a user looking at their own.
    if userid comes via params, then it's an admin looking someone else.
*/

export default function UserProfile({ userId, isAdmin }) {
    const ctx = useContext(UserSettingsContext);
    const navigate = useNavigate();
    const { userid } = useParams();
    const [id, setId] = useState();

    useEffect(() => {
        // if id doesn't exist, leave
        if (!userid && !userId) {
            navigate("/");
            return;
        }

        // if admin and looking at user
        if (userid && isAdmin) setId(userid);
        else if (userId)
            // if normal user looking at themself
            setId(userId);
        // some other combo which is bad so leave
        else navigate("/");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // load up the user when the id is set
    useEffect(() => {
        if (id) ctx.loadUser(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    // get a spoofing token and load it up on the site
    const loginSpoof = () => {
        if (!isAdmin) return;

        ctx.setLoading(true);
        authenticationService
            .getSpoofToken(userid)
            .then((data) => {
                window.open(
                    `${ctx.system.app_url}/app/spoof/${userid}/${data}`,
                    "Popup",
                    "toolbar=yes, location=yes, statusbar=yes, menubar=yes, scrollbars=1, resizable=1, width=580, height=600, top=30"
                );
            })
            .catch((err) => notify.error(err))
            .finally(() => ctx.setLoading(false));
    };

    let defTab = 1;

    switch (window.location.hash) {
        case "#info":
            defTab = 1;
            break;
        case "#password":
            defTab = 2;
            break;
        case "#roles":
            defTab = 3;
            break;
        case "#props":
            defTab = 4;
            break;
        default:
            defTab = 1;
    }

    let title = isAdmin ? <IntlMessages id="manage.users.title" /> : <IntlMessages id="app.user.title" />;
    let extra = isAdmin ? (
        <span>
            <Button color="btn btn-light" onClick={() => navigate("/manage/security")} title="View All Users">
                View All Users
            </Button>
        </span>
    ) : (
        ""
    );

    return (
        <Card title={title} extra={extra}>
            <div>
                <Row gutter={[16, 16]}>
                    <Col span={2}>
                        <img src={ctx.user.thumbnailUrl} alt={ctx.user.fullName} className="w-100" />
                    </Col>
                    <Col span={20}>
                        <h2 className="mb-0">
                            {ctx.user.firstName} {ctx.user.lastName}
                        </h2>
                        <p className="mb-0">
                            <small>
                                <em>created: {moment(ctx.user.createdOn).format("LL")}</em>
                                <br />
                                <em>last login: {moment(ctx.user.lastLoginOn).format("LL")}</em>
                            </small>
                        </p>

                        {isAdmin && (
                            <div className="m-2">
                                {ctx.system?.app_url && (
                                    <Button onClick={loginSpoof} className="ant-btn ant-btn-secondary">
                                        Login As User
                                    </Button>
                                )}
                            </div>
                        )}
                    </Col>
                    <Col span={2} className="text-right">
                        {!ctx.user.isDeleted && (
                            <Popconfirm
                                title={isAdmin ? "Are you sure you want to delete this user?" : "Are you sure you want to remove your account?"}
                                onConfirm={() => ctx.deleteUser()}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button type="primary" danger>
                                    <DeleteOutlined />
                                </Button>
                            </Popconfirm>
                        )}
                        {ctx.user.isDeleted && (
                            <Popconfirm title="Are you sure you want to restore this user?" onConfirm={() => ctx.restoreUser()} okText="Yes" cancelText="No">
                                <Button type="secondary" danger>
                                    Restore
                                </Button>
                            </Popconfirm>
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Tabs defaultActiveKey={defTab.toString()}>
                            <TabPane tab="Profile" key="1">
                                <UserProfileInfo />
                            </TabPane>

                            <TabPane tab="Password" key="2">
                                <UserPassword />
                            </TabPane>

                            {
                                // admins only for roles
                                isAdmin && (
                                    <TabPane tab="Roles" key="3">
                                        <UserRoles />
                                    </TabPane>
                                )
                            }

                            <TabPane tab="Properties" key="4">
                                <UserProperties />
                            </TabPane>
                        </Tabs>
                    </Col>
                </Row>
            </div>
        </Card>
    );
}
