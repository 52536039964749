import React, { useContext, useEffect } from 'react';
import PropEditor from 'components/forms/PropEditor';
import UserSettingsContext from '../context/UserSettingsContext';

const UserProperties = () => {
    const ctx = useContext(UserSettingsContext);

    // load up the initial data
    useEffect(() => {
        loadInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // load the user's props
    const loadInfo = () => {
        ctx.loadProperties();
    }
 
    // save the props
    const handleFinish = values => {
        // save the props
        ctx.saveProperties(values);
    };

    
    return(

        <PropEditor 
            properties={ctx.properties} 
            loading={ctx.loading}
            onSubmit={handleFinish} />

    )

};
export default UserProperties