import React from "react";
import { Route, Routes } from "react-router-dom";
import Layout from "components/MasterLayout";
import List from "./list";
import AgencyList from "./agencyList";
import Queue from "./queue";
import Agency from "./agency";
import Credential from "./credential";
import Verify from "./verify";

const Credentials = () => (
    <Layout>
        <Routes>
            <Route path="list" element={<List />} />
            <Route path="credential" element={<Credential />} />
            <Route path="credential/:id" element={<Credential />} />
            <Route path="queue" element={<Queue />} />
            <Route path="queue/verify/:id" element={<Verify />} />
            <Route path="agencies" element={<AgencyList />} />
            <Route path="agencies/agency" element={<Agency />} />
            <Route path="agencies/agency/:id" element={<Agency />} />
        </Routes>
    </Layout>
);

export default Credentials;
