import React, { useEffect } from "react";
import { Form, Button, Input } from "antd";
import ListInput from "./props/ListInput";
import NumberInput from "./props/NumberInput";
import DatePickerInput from "./props/DatePickerInput";
import YesNoInput from "./props/YesNoInput";
import ReferenceInput from "./props/ReferenceInput";
import MediaInput from "./props/MediaInput";
import WysiwygInput from "./props/WysiwygInput";
import FormInput from "./props/FormInput";
import { jsonHelpers } from "util/JsonHelpers";
import moment from "moment";

const PropEditor = (props) => {
    let { properties, loading, onSubmit, hideSubmit, onFieldsChange, layout, debug = false } = props;
    const [form] = Form.useForm();
    const labelCol = layout === "vertical" ? 24 : 4;
    const wrapperCol = layout === "vertical" ? 24 : 20;
    const offsetCol = layout === "vertical" ? 0 : 4;

    useEffect(() => {
        // turn properties array into object and update the form fields
        let p = properties.reduce((a, v) => ({ ...a, [v.slug]: v.value }), {});
        form.setFieldsValue({ ...p }); // reset fields if "Add Another"
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [properties]);

    // notify the parent
    const handleFinish = (values) => {
        // convert any array values to json strings
        for (let prop in values) {
            values[prop] = Array.isArray(values[prop]) ? JSON.stringify(values[prop]) : values[prop];
        }

        onSubmit(values);
    };

    // send the field change up to the parent to deal with
    const handleValueChanges = (changedFields, allFields) => {
        if (onFieldsChange) onFieldsChange(changedFields[0].name[0], changedFields[0].value);
    };

    if (properties.length === 0) {
        // don't show a message if editing content
        if (hideSubmit) return <></>;
        else {
            // showing a message for editing a user is just fine
            return <div>There are no properties at this time.</div>;
        }
    } else {
        // console.log(properties);

        return (
            <div>
                <Form form={form} labelCol={{ span: labelCol }} wrapperCol={{ span: wrapperCol }} onFinish={handleFinish} onFieldsChange={handleValueChanges} layout={layout}>
                    {properties.map((o, i) => {
                        // sure options to json
                        o.opts = jsonHelpers.parseJson(o.options);

                        switch (o.fieldType) {
                            case 1: // email
                                return (
                                    <Form.Item
                                        key={i}
                                        label={o.label}
                                        name={o.slug}
                                        rules={[
                                            { required: o.isRequired, message: "Required" },
                                            { type: "email", message: "Email is not valid" },
                                        ]}
                                        initialValue={o.value?.replaceAll("\n", "\\n")}
                                    >
                                        <Input placeholder={o.label} />
                                    </Form.Item>
                                );

                            case 2: // url
                                return (
                                    <Form.Item
                                        key={i}
                                        label={o.label}
                                        name={o.slug}
                                        rules={[
                                            { required: o.isRequired, message: "Required" },
                                            { type: "url", message: "URL is not valid" },
                                        ]}
                                        initialValue={o.value}
                                    >
                                        <Input placeholder={o.label} />
                                    </Form.Item>
                                );

                            case 3: // phone number
                                return (
                                    <Form.Item
                                        key={i}
                                        label={o.label}
                                        name={o.slug}
                                        rules={[
                                            { required: o.isRequired, message: "Required" },
                                            {
                                                pattern: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
                                                message: "Phone is not valid",
                                            },
                                        ]}
                                        initialValue={o.value}
                                    >
                                        <Input placeholder={o.label} />
                                    </Form.Item>
                                );

                            case 4: // password
                                return (
                                    <Form.Item key={i} label={o.label} name={o.slug} initialValue={o.value} rules={[{ required: o.isRequired, message: "Required" }]}>
                                        <Input.Password placeholder={o.label} />
                                    </Form.Item>
                                );

                            case 5: // basic paragraph
                                return (
                                    <Form.Item key={i} label={o.label} name={o.slug} initialValue={o.value} rules={[{ required: o.isRequired, message: "Required" }]}>
                                        <Input.TextArea placeholder={o.label} />
                                    </Form.Item>
                                );

                            case 6: // wysiwyg
                                return <WysiwygInput {...o} form={form} onChange={onFieldsChange} key={i} debug={debug} />;

                            case 7: // datetimepicker
                                o.value = o.value ? moment(o.value) : "";
                                return <DatePickerInput {...o} key={i} showTime={true} />;

                            case 8: // datepicker
                                o.value = o.value ? moment(o.value) : "";
                                return <DatePickerInput {...o} key={i} />;

                            case 9: // timepicker
                                o.value = o.value ? moment(o.value, "HH:mm:ss") : "";
                                return <DatePickerInput {...o} key={i} />;

                            case 10: // number
                                return <NumberInput {...o} key={i} />;

                            case 11: // currency
                                return <NumberInput {...o} key={i} type="currency" />;

                            case 12: // media
                                return <MediaInput {...o} form={form} onChange={onFieldsChange} key={i} debug={debug} />;

                            case 13: // refrence
                                return <ReferenceInput {...o} form={form} onChange={onFieldsChange} key={i} debug={debug} />;

                            case 15: // yes / no
                                return <YesNoInput {...o} key={i} />;

                            case 16: // list
                                return <ListInput {...o} key={i} />;

                            case 17: // form
                                return <FormInput {...o} key={i} />;

                            default:
                                return (
                                    <Form.Item key={i} label={o.label} name={o.slug} initialValue={o.value} rules={[{ required: o.isRequired, message: "Required" }]}>
                                        <Input placeholder={o.label} />
                                    </Form.Item>
                                );
                        }
                    })}

                    {!hideSubmit && (
                        <Form.Item wrapperCol={{ span: labelCol, offset: offsetCol }}>
                            <Button type="primary" htmlType="submit" loading={loading}>
                                Submit
                            </Button>
                        </Form.Item>
                    )}
                </Form>
            </div>
        );
    }
};

export default PropEditor;
