import React from "react";
import { Form, Switch, Radio } from "antd";

const YesNoInput = (props) => {
    let {
        slug,
        value,
        label,
        opts,

        error,
        className,
    } = props;

    if (!slug) {
        throw Error("YesNoInput property: slug required");
    }

    const options = [
        { label: "Yes", value: true },
        { label: "No", value: false },
    ];

    return (
        <Form.Item validateStatus={error ? "error" : ""} name={slug} help={error ? error : ""} label={label} className={className}>
            {opts.fieldStyle === "radio" ? (
                <Form.Item name={slug} initialValue={value} valuePropName="checked">
                    <Radio.Group options={options} />
                </Form.Item>
            ) : (
                <Form.Item name={slug} initialValue={value} valuePropName="checked">
                    <Switch />
                </Form.Item>
            )}
        </Form.Item>
    );
};
export default YesNoInput;
