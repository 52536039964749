import axios from "./AxiosHelper";

// define public methods
export const settingsService = {
    get,
    getHostSetting,
    search,
    post,
    deleteSetting,

    getMyMenus,
    getMenus,
    getMenuById,
    postMenu,
    deleteMenu,

    getSystemHooks,
    getHooks,
    getHook,
    postHook,
    deleteManyHooks,
};

//#region Crud
function get() {
    return axios.getData(`/api/settings`, true);
}

function getHostSetting(name) {
    return axios.getData(`/api/settings/host/${name}`, true);
}

function search(data) {
    return axios.postData(`/api/settings/search`, data, true);
}

function post(data) {
    return axios.upsertData(data.id, `/api/settings`, data, true);
}

function deleteSetting(id) {
    return axios.deleteData(`/api/settings/${id}`, true);
}
//#endregion

//#region Menus
function getMyMenus() {
    return axios.getData(`/api/settings/menu/me`, true);
}

function getMenus() {
    return axios.getData(`/api/settings/menu`, true);
}

function getMenuById(id) {
    return axios.getData(`/api/settings/menu/${id}`, false);
}

function postMenu(data) {
    return axios.upsertData(data.id, `/api/settings/menu`, data, true);
}

function deleteMenu(id) {
    return axios.deleteData(`/api/settings/menu/${id}`, true);
}
//#endregion

//#region
function getSystemHooks() {
    return axios.getData(`/api/settings/hooks/system`, true);
}

function getHooks(portalId) {
    return axios.getData(`/api/settings/hooks/${portalId}`, true);
}

function getHook(portalId, id) {
    return axios.getData(`/api/settings/hooks/${portalId}/${id}`, true);
}

function postHook(values) {
    return axios.upsertData(values.id, `/api/settings/hooks`, values, true);
}

function deleteManyHooks(ids) {
    return axios.deleteMultipleData(`/api/settings/hooks`, ids);
}
//#endregion
