import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import { roleService } from "services/RoleService.js";
import { DeleteOutlined, PlusOutlined, TeamOutlined } from "@ant-design/icons";
import { Tooltip, Table, Popconfirm, Button, Card } from "antd";
import { notify } from "util/Notify";

export default function RoleList() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);

    useEffect(() => {
        setLoading(true);

        roleService
            .get()
            .then((data) => {
                setItems(data);
            })
            .catch((err) => console.log(err))
            .finally(() => setLoading(false));
    }, []);

    const handleDelete = (roleid) => {
        setLoading(true);
        roleService
            .deleteRole(roleid)
            .then((data) => {
                notify.success("The role has been removed");

                // splice out the deleted object
                var idx = data.findIndex((o) => o.id === roleid);
                if (idx > -1) {
                    let list = [...data];
                    list.splice(idx, 1);
                    setItems(list);
                }
            })
            .catch((err) => notify.error(err))
            .finally(() => setLoading(false));
    };

    return (
        <Card
            title={<IntlMessages id="manage.roles.title" />}
            extra={
                <Button type="primary" onClick={() => navigate("/manage/security/role")}>
                    <PlusOutlined /> Add Role
                </Button>
            }
        >
            <div>
                <Table
                    dataSource={items}
                    rowKey={(x) => x.id}
                    loading={loading}
                    size="middle"
                    className="pointer"
                    columns={[
                        {
                            title: "Role",
                            dataIndex: "name",
                            render: (text, record) => (
                                <span>
                                    <NavLink to={`/manage/security/role/${record.id}`}>{text}</NavLink>
                                </span>
                            ),
                        },
                        {
                            title: "Count",
                            dataIndex: "quantity",
                            render: (text, record) => (
                                <span>
                                    <NavLink to={`/manage/security/users/${record.id}`}>
                                        {record.quantity} <TeamOutlined />
                                    </NavLink>
                                </span>
                            ),
                        },
                        {
                            title: "",
                            dataIndex: "id",
                            width: 75,
                            render: (text, record) => (
                                <div className="text-left">
                                    {record.normalizedName !== "ADMINISTRATOR" && (
                                        <Tooltip title="delete">
                                            <Popconfirm title="Are you sure you want to delete this?" onConfirm={() => handleDelete(record.id)} okText="Yes" cancelText="No">
                                                <Button type="secondary" danger>
                                                    <DeleteOutlined />
                                                </Button>
                                            </Popconfirm>
                                        </Tooltip>
                                    )}
                                </div>
                            ),
                        },
                    ]}
                />
            </div>
        </Card>
    );
}
