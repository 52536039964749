import React, { Component } from "react";
import { Tabs, Alert, Button, Input } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
// import { contactService } from 'services/ContactService';
import FileUploader from "components/ui/FileUploader";

const { TabPane } = Tabs;

class LeadImporter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            goods: [],
            bads: [],
            import: "",
        };
    }

    // get the array of urls that were uploaded (probably just one)
    handleUploaded = (urls) => {
        this.setState({
            loading: true,
        });

        /*
        // process the urls to import the leads
        contactService.importUrls(this.props.userId, urls)
            .then(data => {
                this.setState({
                    goods: data.itemsImported,
                    bads: data.itemsSkipped
                });

                // call the parent
                if(this.props.onSuccess)
                    this.props.onSuccess();
            })
            .catch(err => console.log(err))
            .finally(() => {
                this.setState({
                    loading: false
                })
            });
            */
    };

    handleImportFromText = () => {
        this.setState({
            loading: true,
        });

        // let leads = this.state.import.split("\n");

        /*
        // process the urls to import the leads
        contactService.importBulk(this.props.userId, leads)
            .then(data => {
                this.setState({
                    goods: data.itemsImported,
                    bads: data.itemsSkipped
                });

                // call the parent
                if(this.props.onSuccess)
                    this.props.onSuccess();
            })
            .catch(err => console.log(err))
            .finally(() => {
                this.setState({
                    loading: false
                })
            });
            */
    };

    render() {
        return (
            <div>
                {this.state.goods.length > 0 && <Alert message={`${this.state.goods.length} items were added`} type="success" showIcon className="mb-2" />}
                {this.state.bads.length > 0 && (
                    <Alert
                        message={`${this.state.bads.length} items were skipped`}
                        description="Items can be skipped either because of bad formatting or to prevent duplicates."
                        type="error"
                        showIcon
                        className="mb-2"
                    />
                )}

                <Tabs>
                    <TabPane tab="Import From File" key="1">
                        <FileUploader accept=".txt,.csv" onUploaded={this.handleUploaded} />
                        <br />
                        <div className="text-right">
                            <a href="https://ideafitnewsletter.blob.core.windows.net/images/example-client-import.csv" className="ant-btn ant-btn-secondary">
                                <DownloadOutlined /> Download example
                            </a>
                        </div>
                    </TabPane>

                    <TabPane tab="Import From Text" key="2">
                        <Alert message="Bulk Import Instructions" description="Entries must be 1 per line in the format of: email,first,last" type="info" showIcon className="mb-2" />
                        <Input.TextArea rows="10" placeholder="someone@domain.com,John,Smith" value={this.state.import} onChange={(v) => this.setState({ import: v.target.value })} />
                        <Button onClick={this.handleImportFromText} type="primary" className="mt-3">
                            Submit
                        </Button>
                    </TabPane>
                </Tabs>
            </div>
        );
    }
}

export default LeadImporter;
