import React, { useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LeadEdit from "./components/Edit";
import SystemContext from "context/SystemContext";

export default function Lead() {
    const ctx = useContext(SystemContext);
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        // update - get the data for this id
        if (id !== undefined) {
            this.setState({
                id: parseInt(id, 10),
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmit = () => {
        navigate("/app/leads");
    };

    return <div>{<LeadEdit ownerId={ctx.id} id={id} onSubmit={handleSubmit} />}</div>;
}
