import React, { useContext } from "react";
import { Menu } from "antd";
import { NavLink } from "react-router-dom";
import SystemContext from "context/SystemContext";

const { SubMenu } = Menu;

const SiteMenu = ({ mode }) => {
    const ctx = useContext(SystemContext);
    let nav = ctx.menus.find((o) => o.slug === "main-menu");
    let adminNav = ctx.menus.find((o) => o.slug === "admin-menu");

    return (
        <Menu inlineIndent={12} mode={mode}>
            {mode === "inline" && nav && nav.tree.map((o, i) => <MenuItem {...o} key={`mainmenu${i}`} />)}
            {mode === "inline" && adminNav && <Menu.Divider />}
            {adminNav && adminNav.tree.map((o, i) => <MenuItem {...o} key={`adminmenu${i}`} />)}
        </Menu>
    );
};

export default SiteMenu;

function MenuItem(props) {
    if (Array.isArray(props.children) && props.children.length > 0) {
        return (
            // Spreading props here does the trick!
            <SubMenu icon={props.icon} title={props.name} {...props}>
                {props.children.map((item) => (
                    <MenuItem {...item} key={item.key} />
                ))}
            </SubMenu>
        );
    }

    return (
        // Spread here too!
        <Menu.Item key={props.key} icon={props.icon} {...props}>
            <NavLink to={props.url}>
                <span>{props.title}</span>
            </NavLink>
        </Menu.Item>
    );
}
