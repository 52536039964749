import React, { Component } from 'react';
import { Button, Card, Spin, Table, Popconfirm} from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined, MenuOutlined } from '@ant-design/icons';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { systemService } from 'services/SystemService';
import { arrayMoveImmutable } from 'array-move';
import { notify } from 'util/Notify';
import SystemListModal from './SystemListModal';

const DragHandle = sortableHandle(() => (
    <MenuOutlined style={{ cursor: 'pointer', color: '#999' }} />
));

const SortableItem = sortableElement(props => <tr {...props} />);
const SortableContainer = sortableContainer(props => <tbody {...props} />);

const operations = {
    Add: "Add",
    Edit: "Edit",
};

class SystemListItems extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: 0,
            loading: false,
            isCustom: false,
            items: null,
            isDirty: false,
            operation: false,
            selectedKeys: [],
            isBulk: false,
            selected: null
        }      
    }

    componentDidMount() {
        const {id} = this.props;

        // make sure we have an id
        if(!id)
            return;

        // the id is the systemlist.id
        this.setState({
            id: parseInt(id, 10)
        }, this.load);
    }

    load = () => {
        
        this.setState ({ loading: true });

        systemService.getSystemList(this.state.id)
            .then(data => {
                // console.log(data);
                this.setState ({
                    items: data.items,
                    isCustom: data.isCustom,
                    isDirty: false,
                    operation: null
                });
            })
            .catch(err => console.log(err))
            .finally(() => {                
                this.setState ({ loading: false });
            })        
    }

    //#region Dragging
    onSortEnd = ({ oldIndex, newIndex }) => {        
        const { items } = this.state;
        if (oldIndex !== newIndex) {
            const newData = arrayMoveImmutable([].concat(items), oldIndex, newIndex).filter(el => !!el);
            this.setState({ 
              items: newData,
              isDirty: true
            });
        }
    };
    
    DraggableBodyRow = ({ className, style, ...restProps }) => {
        const { items } = this.state;
        // function findIndex base on Table rowKey props and should always be a right array index
        const index = items.findIndex(x => x.id === restProps['data-row-key']);
        return <SortableItem index={index} {...restProps} />;
    };
    //#endregion
    
    //#region Modal methods
    // open the modal
    updateOperation = async (operation, selected) => {

        if(!selected)
            selected = { 
                id: 0,
                systemListId: this.state.id 
            };

        this.setState({ 
            operation,
            selected
        });
    }

    // reload the table when add or edit happens with a multi-value item
    handleDrawerOk = (values) => {
        this.load();
    }

    // add an item to the list via the modal
    handleOk = (values, isBulk, closeModal) => {

        if(!isBulk) {                        
            switch (this.state.operation) {
                case operations.Edit:
                    this.setState({
                        items: this.state.items.map(o => o.id === values.id ? values : o)
                    })
                    break;
                default: // add
                    this.setState({
                        items: [ ...this.state.items, values]
                    });
                    break;
            }

            if(closeModal)        
                this.updateOperation(null);
            
            this.setState({ 
                isDirty: true            
            });
        }
        else {            
            // get all rows that aren't empty
            const rows = values.bulk.split('\n').filter(o => { return o.trim().length > 0 });
            // form new items
            const newItems = rows.map((o,i) => {
                const r = o.split('|');
                return {
                    id: generateId(),
                    label: r[0],
                    value: r.length > 1 ? r[1] : r[0]
                };
            });

            // add the new list to the state
            if(newItems.length > 0)
                this.setState({
                    items: [ ...this.state.items, ...newItems]
                });

            if(closeModal)        
                this.updateOperation(null);
            else {
                this.formRef.current.setFieldsValue({ id: generateId() });
                this.labelRef.current.focus();
            }
            
            this.setState({ 
                isDirty: true
            });
        }        
    }

    // close the modal
    handleCancel = () => {        
        this.updateOperation(null);
    }
    //#endregion
    
    //#region Table methods
    // row selection
    handleSelection = (selectedRowKeys, selectedRows) => {
        let sel = selectedRowKeys.filter(i => this.state.items.find(o => o.id === i)); // make sure only selecting exising (non-spliced)
        
        // set keys to state
        this.setState({
            selectedKeys: sel
        });
    }

    // remove the selected from the table (but not form the DB until the save button is hit)
    handleDeleteSelected = () => {
        const { selectedKeys, items } = this.state;

        const leftOvers = items.filter((o) => { 
            return !selectedKeys.some((k) => {
                return o.id === k;
            });
        });

        this.setState({ 
            items: leftOvers,
            selectedKeys: [],
            isDirty: true
        });

        /*
        systemService.deleteSystemListItems(id, selectedKeys)
        .then((data) => {
            notify.success("Your items have been removed");
         
            this.setState({ 
                items: leftOvers,
                selectedKeys: [] 
            });
        })
        .catch(err => {
            notify.error(err);
        })
        .finally(() => {
            this.setState({ loading: false });
        });*/ 
    }
    //#endregion

    //#region Saving
    // save the list to the db
    handleSave = () => {
        this.setState({ loading: true });

        const data = {
            id: this.state.id,
            items: this.state.items.map((o,i) => {
                return { ...o, systemListId: this.state.id, sequence: i };
            })
        }

        systemService.putSystemListItems(data)
            .then((data) => {
                notify.success("The list has been updated");
                
                this.setState({ isDirty: false });
            })
            .catch(err => notify.error(err))
            .finally(() => {
                this.setState({ loading: false });
            });
    }
    //#endregion


    render () {
        const DraggableContainer = props => (
            <SortableContainer
              useDragHandle
              helperClass="row-dragging"
              onSortEnd={this.onSortEnd}
              {...props}
            />
        );

        return (
            <div>
            <Card extra={
                <span>
                    <Popconfirm
                        title="Are you sure you want to delete the selected items?"
                        onConfirm={this.handleDeleteSelected}
                        okText="Yes"
                        cancelText="No" 
                        disabled={this.state.selectedKeys.length === 0}
                    >
                        <Button type="secondary" icon={<DeleteOutlined />} disabled={this.state.selectedKeys.length === 0} color="btn btn-light" className="mr-2" title="Delete Items">Delete Selected</Button> 
                    </Popconfirm>
                    <Button type="primary" icon={<PlusOutlined />} color="btn btn-light" onClick={() => this.updateOperation(operations.Add)} title="Add Item">Add Items</Button> 
                </span>
            }>
                {this.state.items !== null ? (
                    <div>

                        <Table
                            pagination={false}
                            dataSource={this.state.items}
                            rowKey="id"
                            size="small"
                            className="mb-3"
                            components={{
                                body: {
                                    wrapper: DraggableContainer,
                                    row: this.DraggableBodyRow,
                                },
                            }}
                            rowSelection={{
                                type: 'checkbox',
                                onChange: this.handleSelection,
                                selectedRowKeys: this.state.selectedKeys
                            }}
                            columns={[
                                {
                                  title: '', dataIndex: 'sort', width: 30, className: 'drag-visible',
                                  render: () => <DragHandle />,
                                },
                                {
                                  title: 'Label', dataIndex: 'label', className: 'drag-visible',
                                },
                                {
                                  title: 'Value', dataIndex: 'value'
                                },
                                {
                                    title: 'Actions', align: 'center', width: 200,
                                    render: (text,record) => (
                                        <Button type="primary" className="mr-2" onClick={() => this.updateOperation(operations.Edit, record)}><EditOutlined /></Button>    
                                    )
                                }
                            ]}
                        />

                        <Button 
                            type="primary"
                            loading={this.state.loading}
                            disabled={!this.state.isDirty}                            
                            onClick={this.handleSave}
                        >
                            Save
                        </Button>
                    </div>
                ) : (
                    <Spin size="large" />
                )}
            </Card>

            {
                this.state.selected && 
                    <SystemListModal
                        operation={this.state.operation}
                        operations={operations}
                        onSubmit={this.handleOk}
                        onCancel={this.handleCancel}
                        existing={this.state.selected}
                        loading={this.state.loading}
                    />
            }

            
            </div>
        );
    }
};

export default SystemListItems;

function generateId() {    
    return -1 * Math.floor(Math.random() * (999999 + 1));
}