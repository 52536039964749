import React from 'react';
import { Card, Row, Col, Typography, Button, Popconfirm } from 'antd';
import MediaLibraryContext from '../context/MediaLibraryContext';
import { LinkOutlined, DeleteOutlined } from '@ant-design/icons';


class MediaModal extends React.Component {
    static contextType = MediaLibraryContext;

    render() {

        if(this.context.media !== null)
        {
            return (
                <Card
                    title={this.context.media.title}
                    extra={
                        <Popconfirm
                            title="Are you sure you want to delete this item?"
                            onConfirm={this.context.deleteMedia}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="secondary" danger><DeleteOutlined /></Button>
                        </Popconfirm>                        
                    }
                >            
    
                    <Row gutter={[16,16]}>
                        <Col span={8} className="text-center">
                            <img src={this.context.media.thumbnail} className="img-fluid" alt="thumbnail" />
                        </Col>
                        <Col span={16}>
                            <label>Title</label><br />
                            <Typography.Text copyable={true} keyboard>
                                {this.context.media.title}
                            </Typography.Text>

                            <br /><br />

                            <label>File Name</label><br />
                            <Typography.Text copyable={true} keyboard>
                                {this.context.media.name}
                            </Typography.Text>

                            <br /><br />

                            <label>Slug</label><br />
                            <Typography.Text copyable={true} keyboard>
                                {this.context.media.slug}
                            </Typography.Text>

                            <br /><br />

                            <label>Url</label><br />
                            <Typography.Text copyable={true} keyboard>
                                {this.context.media.url}
                            </Typography.Text>

                            <a href={this.context.media.url} target="_blank" rel="noopener noreferrer"><LinkOutlined className="primary ml-1" /></a>

                            <br /><br />

                            <label>File Size</label><br />
                            <Typography.Text copyable={true} keyboard>
                                {this.context.media.fileSize}
                            </Typography.Text>

                            {
                                this.context.media.mediaType === 0 &&
                                <span>
                                    <br /><br />

                                    <label>Dimensions</label><br />
                                    <Typography.Text copyable={true} keyboard>
                                        {this.context.media.width} x {this.context.media.height}
                                    </Typography.Text>
                                </span>
                            }
                            
                        </Col>
                    </Row>

    
                </Card>
            );    
        }
        else 
            return null;
    }
}

export default MediaModal;