import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Table, Button, Card } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { systemService } from "services/SystemService.js";

export default function SystemListAll() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);

    useEffect(() => {
        setLoading(true);

        systemService
            .getSystemLists()
            .then((data) => {
                setItems(data);
            })
            .catch((err) => console.log(err))
            .finally(() => setLoading(false));
    }, []);

    return (
        <Card
            title="Lists"
            extra={
                <Button type="primary" icon={<PlusOutlined />} color="btn btn-light" onClick={() => navigate("/manage/lists/list/")} title="Add List">
                    Add List
                </Button>
            }
        >
            <div>
                <Table
                    dataSource={items}
                    rowKey={(x) => x.id}
                    pagination={false}
                    loading={loading}
                    size="middle"
                    className="clear-both pointer"
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: (e) => {
                                navigate(`/manage/lists/list/${record.id}`);
                            },
                        };
                    }}
                    columns={[
                        { title: "Name", dataIndex: "name" },
                        { title: "Slug", dataIndex: "slug" },
                        { title: "Description", dataIndex: "description" },
                        { title: "Quantity", dataIndex: "quantity" },
                    ]}
                />
            </div>
        </Card>
    );
}
