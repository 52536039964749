import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { credentialService } from "services/CredentialService.js";
import { StopTwoTone, CheckCircleTwoTone, PlusOutlined } from "@ant-design/icons";
import { Table, Button, Card, Input, Row, Col, Select } from "antd";
import moment from "moment";

const { Search } = Input;

export default function CredentialList() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [runSearch, setSearch] = useState(0);
    const [list, setList] = useState([]);
    const [agencies, setAgencies] = useState([]);
    const [keyword, setKeyword] = useState("");
    const [agencyId, setAgencyId] = useState();
    const [sorted, setSorted] = useState({ id: "name", desc: false });
    const [pagination, setPagination] = useState({ current: 1, pageSize: 20, total: 0, position: ["topRight", "bottomRight"], showSizeChanger: true });

    // get agencies
    useEffect(() => {
        var args = {
            keyword: "",
            orderBy: { id: "name", desc: false },
            extra: "active",
            pagination: {
                pageNum: 1,
                pageSize: 10000,
            },
        };

        credentialService
            .searchAgencies(args)
            .then((data) => {
                setAgencies(data.items);
            })
            .catch((err) => console.log(err));
    }, []);

    useEffect(() => {
        setLoading(true);

        var args = {
            keyword,
            orderBy: sorted,
            extra: agencyId,
            pagination: {
                pageNum: pagination.current,
                pageSize: pagination.pageSize,
            },
        };

        // get credentials
        credentialService
            .searchCredentials(args)
            .then((data) => {
                setList(data.items);

                const pager = { ...pagination };
                pager.total = data.totalRecords;
                setPagination(pager);
            })
            .catch((err) => console.log(err))
            .finally(() => setLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [runSearch]);

    const handleTableChange = (paging, filters, sorter) => {
        let s = { ...sorted };
        const pager = { ...pagination };
        if (pager.pageSize !== paging.pageSize) {
            pager.pageSize = paging.pageSize;
            pager.current = 1;
        } else {
            pager.current = paging.current;
        }

        if (sorter.field) s = { id: sorter.field, desc: sorter.order === "descend" };

        setSorted(s);
        setPagination(pager);
        setSearch(runSearch + 1);
    };

    const handleAgencyChange = (s) => {
        const pager = { ...pagination };
        pager.current = 1;
        setPagination(pager);
        setAgencyId(s);
        setSearch(runSearch + 1);
    };

    return (
        <Card
            title={"Manage Credentials"}
            extra={
                <Button type="primary" icon={<PlusOutlined />} color="btn btn-light" onClick={() => navigate(`/manage/credentials/credential`)} title="Add Credential">
                    Add Credential
                </Button>
            }
        >
            <div>
                <Row>
                    <Col sm={24} md={16} className="text-right">
                        <Select
                            placeholder="Select Agency"
                            showSearch
                            value={agencyId}
                            onChange={handleAgencyChange}
                            className="mr-2 w-40"
                            optionFilterProp="children"
                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                        >
                            <Select.Option value="">Any Agency</Select.Option>
                            {agencies.map((o, i) => {
                                return (
                                    <Select.Option key={i} value={o.id}>
                                        {o.name}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Col>
                    <Col sm={24} md={8} className="text-right">
                        <Search
                            id="keyword"
                            placeholder="search"
                            onSearch={() => setSearch(runSearch + 1)}
                            enterButton
                            className="w-100"
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                            loading={loading}
                        />
                    </Col>
                </Row>

                <Table
                    dataSource={list}
                    rowKey={(x) => x.id}
                    pagination={pagination}
                    loading={loading}
                    size="middle"
                    className="clear-both pointer"
                    onChange={handleTableChange}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: (e) => {
                                navigate(`/manage/credentials/credential/${record.id}`);
                            },
                        };
                    }}
                    columns={[
                        { title: "Name", dataIndex: "name", sorter: true },
                        {
                            title: "Credentialing Agency",
                            dataIndex: "agencyName",
                            sorter: true,
                            render: (text, record) => <span>{record.agency.name}</span>,
                        },
                        { title: "Renewal Type", dataIndex: "renewalTypeName" },
                        {
                            title: "Status",
                            dataIndex: "status",
                            align: "center",
                            render: (text, record) => (
                                <span>
                                    {record.status && <CheckCircleTwoTone twoToneColor="#52c41a" />}
                                    {!record.status && <StopTwoTone twoToneColor="#aa3333" />}
                                </span>
                            ),
                        },
                        {
                            title: "Updated",
                            dataIndex: "updatedOn",
                            sorter: true,
                            render: (text, record) => <span>{record.updatedOn && moment(record.updatedOn).format("LL")}</span>,
                        },
                    ]}
                />
            </div>
        </Card>
    );
}
