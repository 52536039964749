import React from "react";
// import { withRouter } from "react-router-dom";
import SystemContext from "context/SystemContext";
import MediaLibraryContext from "./MediaLibraryContext";
import { mediaService } from "services/MediaService.js";
import { notify } from "util/Notify";

class MediaLibraryState extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            medialist: [], // list of media
            media: null, // single selected
            thumbnailUrl: "",
            loading: false,
            pages: 0,
            keyword: "",
            mediaTypes: [],
            pagination: { current: 1, pageSize: 20, position: ["topRight", "bottomRight"], showSizeChanger: true },
            sorted: { id: "createdOn", desc: true },
            gridview: true,
            modal: false,
            selectedKeys: [],
        };
    }

    setLoading = (boo) => {
        this.setState({ loading: boo });
    };

    search = () => {
        this.setLoading(true);
        let { pagination, sorted, keyword, mediaTypes } = this.state;

        // structure the args
        let data = {
            keyword: keyword,
            mediaTypes: mediaTypes,
            orderBy: sorted,
            pagination: {
                pageNum: pagination.current,
                pageSize: pagination.pageSize,
            },
        };

        mediaService
            .get(data)
            .then((res) => {
                const pager = { ...this.state.pagination };
                pager.total = res.totalRecords;

                this.setState({
                    medialist: res.media,
                    pagination: pager,
                });
            })
            .catch((err) => console.log(err))
            .finally(() => {
                this.setLoading(false);
            });
    };

    setMedia = (o) => {
        this.setState({
            media: o,
            modal: true,
        });
    };

    load = (id) => {
        // ensure a number
        id = parseInt(id, 10);

        this.setLoading(true);

        mediaService
            .getByIdOrSlug(id)
            .then((data) => {
                this.setState({
                    media: data,
                });
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                this.setLoading(false);
            });
    };

    deleteMedia = () => {
        this.setLoading(true);
        mediaService
            .deleteMedia(this.state.media.id)
            .then((data) => {
                notify.success("The media has been deleted");

                // close the modal if it's open
                this.toggleModal(false);

                // reload the data
                this.search();
            })
            .catch((err) => {
                notify.error(err);
            })
            .finally(() => {
                this.setLoading(false);
            });
    };

    deleteSelected = () => {
        // make sure we have some, otherwise, just leave
        if (this.state.selectedKeys.length === 0) return;

        this.setLoading(true);
        mediaService
            .deleteMediaList(this.state.selectedKeys)
            .then((data) => {
                notify.success("The selected media has been deleted");

                // close the modal if it's open
                this.toggleModal(false);

                this.setState({ selectedKeys: [] });

                // reload the data
                this.search();
            })
            .catch((err) => {
                notify.error(err);
            })
            .finally(() => {
                this.setLoading(false);
            });
    };

    handleField = (evt) => {
        let name = evt.target.name;
        let val = evt.target.value;
        let media = this.state.media;

        media[name] = val;
        this.setState({ media });
    };

    handleTableChange = (paging, filters, sorter) => {
        let s = { ...this.state.sorted };
        const pager = { ...this.state.pagination };
        if (pager.pageSize !== paging.pageSize) {
            pager.pageSize = paging.pageSize;
            pager.current = 1;
        } else {
            pager.current = paging.current;
        }

        if (sorter.field) this.setLoading = { id: sorter.field, desc: sorter.order === "descend" };

        this.setState(
            {
                pagination: pager,
                s,
            },
            this.search
        );
    };

    handleFieldChange = (evt) => {
        this.setState({ [evt.target.id]: evt.target.value });
    };

    handleSearch = (keyword) => {
        const pager = { ...this.state.pagination };
        pager.current = 1;
        this.setState({ pagination: pager }, this.search);
    };

    handleMediaTypeChange = (ids) => {
        const pager = { ...this.state.pagination };
        pager.current = 1;
        this.setState(
            {
                pagination: pager,
                mediaTypes: ids,
            },
            this.search
        );
    };

    toggleModal = (boo) => {
        if (boo === undefined || typeof boo !== "boolean") boo = !this.state.modal;
        this.setState({
            modal: boo,
            media: null,
        });
    };

    handleSuccess = () => {
        // show a woo-hoo
        notify.success("Your media has been added");

        // hide modal
        this.toggleModal(false);

        // reload
        this.search();
    };

    // row selection
    handleSelection = (selectedRowKeys, selectedRows) => {
        let sel = selectedRowKeys.filter((i) => this.state.medialist.find((o) => o.id === i)); // make sure only selecting exising (non-spliced)

        // set keys to state
        this.setState({
            selectedKeys: sel,
        });
    };

    render() {
        return (
            <MediaLibraryContext.Provider
                value={{
                    medialist: this.state.medialist,
                    media: this.state.media,
                    loading: this.state.loading,
                    modal: this.state.modal,
                    pagination: this.state.pagination,
                    selectedKeys: this.state.selectedKeys,

                    setLoading: this.setLoading,
                    search: this.search,
                    setMedia: this.setMedia,
                    load: this.load,
                    handleField: this.handleField,
                    handleTableChange: this.handleTableChange,
                    handleFieldChange: this.handleFieldChange,
                    handleSearch: this.handleSearch,
                    handleMediaTypeChange: this.handleMediaTypeChange,
                    toggleModal: this.toggleModal,
                    handleSuccess: this.handleSuccess,
                    handleSelection: this.handleSelection,
                    deleteMedia: this.deleteMedia,
                    deleteSelected: this.deleteSelected,

                    // from SystemState
                    portalId: this.context.portalId,
                    subscriptionId: this.context.subscriptionId,
                }}
            >
                {this.props.children}
            </MediaLibraryContext.Provider>
        );
    }
}

MediaLibraryState.contextType = SystemContext;
export default MediaLibraryState;
