import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Layout from 'components/MasterLayout';
import SystemPropsList from './systemPropList';
import SystemProp from './systemProp';

export default function SystemProps () {

    return (
        <Layout>
            <Routes>
                <Route index element={<SystemPropsList />} />
                <Route path="profileprop/:id" element={<SystemProp />} />
                <Route path="profileprop/" element={<SystemProp />} />
                <Route element={<SystemPropsList />} />
            </Routes>
        </Layout>
    );
}; 