import React from "react";
import SystemContext from "./SystemContext";
import { userService } from "services/UserService";
import { settingsService } from "services/SettingsService";
import { notify } from "util/Notify";

class SystemState extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            system: null,
            user: {},
            roles: [],
            properties: [],
            claims: [],
            menus: [],
            loading: false,
            token: "",
            portalId: null,
        };
    }

    /// loads when page loads
    componentWillMount() {
        // if we have the localstorage info, then set the state using it
        let info = localStorage.getItem("user_info") && JSON.parse(window.atob(localStorage.getItem("user_info")));
        if (info !== null) this.setSystemState(info);

        // get system settings
        /*if(this.state.system === null) {
            this.getSystemSettings();
        }*/
    }

    /// sets the loading var for api processing
    setLoading = (boo) => {
        this.setState({ loading: boo });
    };

    /// accepts a user object and serializes it to localstorage
    setUser = (user) => {
        localStorage.setItem("user_info", window.btoa(JSON.stringify(user)));
        this.setSystemState(user);
    };

    /// sets various state vars for the site based on the user object
    setSystemState = (user) => {
        let roles = user.userRoles || [];
        let menus = user.menus || [];
        let claims = user.claims || [];
        let portalId = user.portalId;

        // set global variable to be used in AxiosHelper calls
        window.$portalId = portalId;

        delete user.token;
        delete user.userRoles;
        delete user.menus;
        delete user.claims;
        delete user.portalId;

        this.setState({
            roles,
            user,
            menus,
            claims,
            portalId,
        });
    };

    // Get User
    getUser = () => {
        try {
            // if user is defined, then just return it
            if (this.state.user.id !== undefined) {
                return this.state.user;
            }

            return null;
        } catch (err) {
            localStorage.removeItem("user_info");
            return null;
        }
    };

    // get system info
    getSystemSettings = () => {
        settingsService
            .get()
            .then((data) => {
                this.setState({
                    system: data,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    };

    loadProperties = (callback) => {
        userService
            .getProperties(this.state.user.id)
            .then((data) => {
                this.setState(
                    {
                        properties: data,
                    },
                    () => {
                        if (callback) callback(); // provide callback function
                    }
                );

                return data;
            })
            .catch((err) => {
                return null;
            });
    };

    saveProperties = (values, msgSuccess, callback) => {
        this.setLoading(true);

        userService
            .postProperties(this.state.user.id, values)
            .then((data) => {
                // also refresh the user in local storage
                this.refreshUser(callback);

                notify.success(msgSuccess === undefined ? "The properties have been updated" : msgSuccess);
            })
            .catch((err) => notify.error(err))
            .finally(() => {
                this.setLoading(false);
            });
    };

    getProperty = (slug) => {
        let prop = "";

        // see if we have props already and get them if we don't
        if (this.state.properties.length === 0) {
            this.loadProperties(() => (prop = this.getPropertyInternal(slug)));
            return prop;
        }

        return this.getPropertyInternal(slug);
    };

    getPropertyInternal = (slug) => {
        let prop = this.state.properties.find((o) => o.slug === slug.toLowerCase());
        return prop === undefined ? "" : prop.value;
    };

    logout = () => {
        localStorage.removeItem("user_info");
        this.setState(
            {
                user: {},
                roles: [],
                claims: [],
                loading: false,
            },
            () => {
                window.location.href = "/login?logoff=1";
            }
        );
    };

    refreshUser = (callback) => {
        userService
            .getSelf(this.state.user.id)
            .then((data) => {
                // copy over the token so we don't lose it
                let token = this.getToken();
                data.token = token;

                // resave the new data
                this.setUser(data);

                // if there is a callback, run it
                if (callback) callback();

                return data;
            })
            .catch((err) => {
                return null;
            });
    };

    refreshMenus = () => {
        return;
        /*settingsService
            .getMyMenus()
            .then((data) => {
                this.setState({
                    menus: data,
                });

                // get the user settings
                let info = localStorage.getItem("user_info") && JSON.parse(window.atob(localStorage.getItem("user_info")));
                if (info !== null) {
                    info.menus = data;
                    localStorage.setItem("user_info", window.btoa(JSON.stringify(info)));
                }
            })
            .catch((err) => {
                console.log("couldn't refresh menus:", err);
            });
            */
    };

    getToken = () => {
        // return authorization header with jwt token
        let info = localStorage.getItem("user_info");
        let user = info !== null ? JSON.parse(window.atob(info)) : null;

        if (user && user.token) {
            return user.token;
        }
        return null;
    };

    render() {
        return (
            <SystemContext.Provider
                value={{
                    system: this.state.system,
                    user: this.state.user,
                    roles: this.state.roles,
                    menus: this.state.menus,
                    loading: this.state.loading,
                    token: this.state.token,
                    portalId: this.state.portalId,

                    setUser: this.setUser,
                    getUser: this.getUser,
                    getSystemSettings: this.getSystemSettings,
                    refreshUser: this.refreshUser,
                    setLoading: this.setLoading,
                    loadProperties: this.loadProperties,
                    saveProperties: this.saveProperties,
                    getProperty: this.getProperty,
                    getToken: this.getToken,
                    refreshMenus: this.refreshMenus,
                    logout: this.logout,
                }}
            >
                {this.props.children}
            </SystemContext.Provider>
        );
    }
}

export default SystemState;
