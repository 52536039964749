import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "components/SimpleLayout";
import { Form, Input, Button, Alert, Card } from "antd";
import { NavLink } from "react-router-dom";
import { authenticationService } from "services/AuthenticationService";
import qs from "qs";
import SystemContext from "context/SystemContext";

export default function ConfirmPassword() {
    const ctx = useContext(SystemContext);
    const navigate = useNavigate();
    const [showError, setShowError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [passwordToken, setPasswordToken] = useState("");
    const [userid, setUserId] = useState("");

    useEffect(() => {
        const q = qs.parse(window.location.search.replace("?", ""));

        // make sure the needed values exist
        if (q.u === undefined || q.p === undefined) {
            navigate("/");
            return;
        }

        setPasswordToken(q.p);
        setUserId(q.u);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleFinish = (values) => {
        ctx.setLoading(true);

        values.userId = userid;
        values.passwordToken = passwordToken;

        // try to confirm the email address && password
        authenticationService
            .confirmPassword(values)
            .then((data) => {
                ctx.setUser(data);
                window.location.href = "/"; // go home
            })
            .catch((err) => {
                setShowError(true);
                setErrorMsg(err);
            })
            .finally(() => {
                ctx.setLoading(false);
            });
    };

    return (
        <Layout>
            <Card className="simple-container">
                <div className="mb-5 text-center">
                    {showError && <Alert message="Error" description={errorMsg} type="error" className="mx-auto w-100 mb-3" showIcon />}

                    <Form labelCol={{ span: 10 }} wrapperCol={{ span: 14 }} onFinish={handleFinish}>
                        <Form.Item label="New Password" name="password" rules={[{ required: true, message: "Please enter a password" }]}>
                            <Input.Password placeholder="password" />
                        </Form.Item>

                        <Form.Item
                            label="Confirm Password"
                            name="confirm"
                            rules={[
                                { required: true, message: "Enter your password again" },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (!value || getFieldValue("password") === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject("The passwords do not match!");
                                    },
                                }),
                            ]}
                        >
                            <Input.Password placeholder="confirm password" />
                        </Form.Item>

                        <Form.Item wrapperCol={{ span: 14, offset: 10 }}>
                            <Button type="primary" htmlType="submit" loading={ctx.loading}>
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Card>
        </Layout>
    );
}
