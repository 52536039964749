import React, { useState, useEffect } from "react";
import { Button, Card, Spin, Table, Popconfirm } from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined, MenuOutlined } from "@ant-design/icons";
import { sortableContainer, sortableElement, sortableHandle } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import SystemListModal from "modules/_Manage/SystemLists/components/SystemListModal";

const DragHandle = sortableHandle(() => <MenuOutlined style={{ cursor: "pointer", color: "#999" }} />);

const SortableItem = sortableElement((props) => <tr {...props} />);
const SortableContainer = sortableContainer((props) => <tbody {...props} />);

const operations = {
    Add: "Add",
    Edit: "Edit",
};

/*
    Notes:
        hideValues: is a boolean that hides the value column in the table when set
*/

export default function ListOptionEditor({ list, onChange, presets, hideValues }) {
    const [loaded, setLoaded] = useState(false);
    const [selected, setSelected] = useState();
    const [items, setItems] = useState([]);
    const [operation, setOperation] = useState();

    useEffect(() => {
        // only run this if we're loading the first time with real data
        if (!list || list.length === 0 || loaded) return;

        // if list doesn't have an id field, create one
        setItems(
            list.map((o, i) => {
                if (!o.id) o.id = i;
                return o;
            })
        );
        setLoaded(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [list]);

    // alert the parent with the updated data
    useEffect(() => {
        onChange(
            items.map((o) => {
                return { label: o.label, value: o.value };
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items]);

    //#region Dragging
    const onSortEnd = ({ oldIndex, newIndex }) => {
        if (oldIndex !== newIndex) {
            const newData = arrayMoveImmutable([].concat(items), oldIndex, newIndex).filter((el) => !!el);
            setItems(newData);
        }
    };

    const DraggableBodyRow = ({ className, style, ...restProps }) => {
        // function findIndex base on Table rowKey props and should always be a right array index
        const index = items.findIndex((x) => x.id === restProps["data-row-key"]);
        return <SortableItem index={index} {...restProps} />;
    };

    const DraggableContainer = (props) => <SortableContainer useDragHandle helperClass="row-dragging" onSortEnd={onSortEnd} {...props} />;
    //#endregion

    //#region Modal methods
    // open the modal
    const updateOperation = async (operation, selected) => {
        if (!selected)
            selected = {
                id: 0,
            };

        setOperation(operation);
        setSelected(selected);
    };

    // add an item to the list via the modal
    const handleOk = (values, isBulk, closeModal) => {
        if (!isBulk) {
            switch (operation) {
                case operations.Edit:
                    setItems(items.map((o) => (o.id === values.id ? values : o)));
                    break;
                default: // add
                    setItems([...items, values]);
                    break;
            }

            if (closeModal) updateOperation(null);
        } else {
            // get all rows that aren't empty
            const rows = values.bulk.split("\n").filter((o) => {
                return o.trim().length > 0;
            });
            // form new items
            const newItems = rows.map((o, i) => {
                const r = o.split("|");
                return {
                    id: generateId(),
                    label: r[0],
                    value: r.length > 1 ? r[1] : r[0],
                };
            });

            // add the new list to the state
            if (newItems.length > 0) setItems([...items, ...newItems]);

            if (closeModal) updateOperation(null);
        }
    };

    // close the modal
    const handleCancel = () => {
        updateOperation(null);
    };
    //#endregion

    //#region Table methods
    // remove the selected from the table
    const handleDelete = (id) => {
        const leftOvers = items.filter((o) => o.id !== id);
        setItems(leftOvers);
    };
    //#endregion

    // hide the value if we don't need it
    let columns = [
        {
            title: "",
            dataIndex: "sort",
            width: 30,
            className: "drag-visible",
            render: () => <DragHandle />,
        },
        { title: "Label", dataIndex: "label" },
        { title: "Value", dataIndex: "value", hideValue: true },
        {
            title: "Actions",
            align: "center",
            render: (text, record) => (
                <span className="nowrap">
                    <Button type="primary" className="mr-1" onClick={() => updateOperation(operations.Edit, record)}>
                        <EditOutlined />
                    </Button>
                    <Popconfirm title="Are you sure you want to delete this?" onConfirm={() => handleDelete(record.id)} okText="Yes" cancelText="No">
                        <Button danger>
                            <DeleteOutlined />
                        </Button>
                    </Popconfirm>
                </span>
            ),
        },
    ];

    if (hideValues) columns = columns.filter((o) => !o.hideValue);

    return (
        <div>
            <Card
                className="card-nopad"
                extra={
                    <Button type="primary" icon={<PlusOutlined />} color="btn btn-light" onClick={() => updateOperation(operations.Add)} title="Add Item">
                        Add Items
                    </Button>
                }
            >
                {items !== null ? (
                    <div>
                        <Table
                            pagination={false}
                            dataSource={items}
                            rowKey={(x) => x.id}
                            size="small"
                            className="mb-3"
                            components={{
                                body: {
                                    wrapper: DraggableContainer,
                                    row: DraggableBodyRow,
                                },
                            }}
                            columns={columns}
                        />
                    </div>
                ) : (
                    <Spin size="large" />
                )}
            </Card>

            {selected && <SystemListModal operation={operation} operations={operations} onSubmit={handleOk} onCancel={handleCancel} existing={selected} loading={false} presets={presets} />}
        </div>
    );
}

function generateId() {
    return -1 * Math.floor(Math.random() * (999999 + 1));
}
