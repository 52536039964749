import React from 'react';
import { useNavigate } from "react-router-dom";
import { Card, Button } from "antd";
import SystemPropertyEditor from 'modules/_Manage/SystemProps/components/SystemPropertyEditor';

const SystemProp = () => {
    const navigate = useNavigate();

    return (
        <Card
            title="Custom Property"
            extra={ <Button color="btn btn-light" onClick={() => navigate('/manage/profileprops')} title="View All">View All Properties</Button> }
        >
            <SystemPropertyEditor propertyType={0} />
        </Card>
    );
};


export default SystemProp;
