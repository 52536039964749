import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Layout from 'components/MasterLayout';
import UserList from './userList';
import User from './user';
import RoleList from './roleList';
import Role from './role';

export default function Security () {

    return (
        <Layout>
            <Routes>
                <Route path="users/:roleid" element={<UserList />} />
                <Route path="user/:userid" element={<User />} />
                <Route path="user/" element={<User />} />
                <Route path="roles" element={<RoleList />} />
                <Route path="role/:id" element={<Role />} />
                <Route path="role/" element={<Role />} />
                <Route path="*" element={<UserList />} />
            </Routes>
        </Layout>
    );
}; 