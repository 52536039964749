import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import { userService } from "services/UserService.js";
import { roleService } from "services/RoleService.js";
import { PlusOutlined } from "@ant-design/icons";
import { Table, Button, Card, Input, Tag, Select } from "antd";
import UserAddModal from "./modals/UserAddModal";

const { Search } = Input;

export default function UserList() {
    const navigate = useNavigate();
    const userAddModal = useRef(null);
    const [loading, setLoading] = useState(false);
    const [runSearch, setSearch] = useState(0);
    const [users, setUsers] = useState([]);
    const [keyword, setKeyword] = useState("");
    const [sorted, setSorted] = useState({ id: "lastname", desc: false });
    const [pagination, setPagination] = useState({ current: 1, pageSize: 20, total: 0, position: ["topRight", "bottomRight"], showSizeChanger: true });
    const [roles, setRoles] = useState([]);
    const [roleId, setRoleId] = useState();
    const { roleid } = useParams();

    useEffect(() => {
        // setup initial roleid if it came in the url
        if (roleid) setRoleId(roleid);

        setLoading(true);

        // get roles
        roleService
            .get()
            .then((data) => {
                setRoles(data);

                // initial load of the table
                setSearch(runSearch + 1);
            })
            .catch((err) => console.log(err))
            .finally(() => setLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (runSearch === 0) return;

        setLoading(true);

        userService
            .get(pagination.current, pagination.pageSize, sorted, keyword, roleId)
            .then((data) => {
                const pager = { ...pagination };
                pager.total = data.totalRecords;

                setUsers(data.users);
                setPagination(pager);
            })
            .catch((err) => console.log(err))
            .finally(() => setLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [runSearch]);

    const handleTableChange = (paging, filters, sorter) => {
        let s = { ...sorted };
        const pager = { ...pagination };
        if (pager.pageSize !== paging.pageSize) {
            pager.pageSize = paging.pageSize;
            pager.current = 1;
        } else {
            pager.current = paging.current;
        }

        if (sorter.field) s = { id: sorter.field, desc: sorter.order === "descend" };

        setSorted(s);
        setPagination(pager);
        setSearch(runSearch + 1);
    };

    const handleRoleChange = (roleid) => {
        setRoleId(roleid);
        setSearch(runSearch + 1);
    };

    const toggleProfile = () => {
        userAddModal.current.handleToggle();
    };

    const userAdded = () => {
        setSearch(runSearch + 1);
    };

    return (
        <Card
            title={<IntlMessages id="manage.users.title" />}
            extra={
                <Button type="primary" icon={<PlusOutlined />} color="btn btn-light" onClick={toggleProfile} title="Add User">
                    Add User
                </Button>
            }
        >
            <div>
                <div className="w-60 mb-3 text-right float-right">
                    <Select id="roleid" value={roleId} onChange={handleRoleChange} className="w-30 mr-2">
                        <Select.Option key={0} value="">
                            All Roles
                        </Select.Option>
                        {roles &&
                            roles.map((p, i) => {
                                return (
                                    <Select.Option key={p.id} value={p.id}>
                                        {p.name}
                                    </Select.Option>
                                );
                            })}
                    </Select>

                    <Search
                        id="keyword"
                        placeholder="search"
                        onSearch={() => setSearch(runSearch + 1)}
                        enterButton
                        className="w-50"
                        value={keyword}
                        onChange={(e) => setKeyword(e.target.value)}
                        loading={loading}
                    />
                </div>

                <Table
                    dataSource={users}
                    rowKey={(x) => x.id}
                    pagination={pagination}
                    loading={loading}
                    size="middle"
                    className="clear-both pointer"
                    onChange={handleTableChange}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: (e) => {
                                navigate(`/manage/security/user/${record.id}`);
                            },
                        };
                    }}
                    columns={[
                        {
                            title: "",
                            dataIndex: "thumbnail",
                            width: "6%",
                            render: (text, record) => <img src={record.thumbnailUrl} alt="Thumbnail" className="img-fluid avatar-50" />,
                        },
                        {
                            title: "Username",
                            dataIndex: "userName",
                            sorter: true,
                            filterMultiple: false,
                            render: (text, record) => (
                                <span>
                                    {!record.isDeleted && <span>{record.userName}</span>}
                                    {record.isDeleted && <span className="is-deleted">{record.userName}</span>}
                                </span>
                            ),
                        },
                        { title: "First", dataIndex: "firstName", sorter: true, filterMultiple: false },
                        { title: "Last", dataIndex: "lastName", sorter: true },
                        { title: "Email", dataIndex: "email", sorter: true },
                        { title: "Phone", dataIndex: "phoneNumber" },
                        {
                            title: "Roles",
                            dataIndex: "userRoles.roleId",
                            sorter: false,
                            render: (text, record) => (
                                <span>
                                    {record.userRoles.map((o, idx) => {
                                        return (
                                            <Tag key={idx} color="blue" className="mb-1">
                                                {o.roleName}
                                            </Tag>
                                        );
                                    })}
                                </span>
                            ),
                        },
                    ]}
                />
            </div>

            <UserAddModal ref={userAddModal} onSuccess={userAdded} />
        </Card>
    );
}
