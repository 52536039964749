import React from 'react';
import { Table, Button, Card, Tag, Typography } from "antd";
import { PlusOutlined } from '@ant-design/icons';
import { settingsService } from 'services/SettingsService';
import SettingModal from './modals/settingModal';
import SystemContext from 'context/SystemContext';

const { Text } = Typography;

class SettingsList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            items: [],
            pagination: { current: 1, pageSize: 20, position: ['topRight', 'bottomRight'], showSizeChanger: true },
            sorted: { id: 'name', desc: false },
            keyword: "",
            selected: null,
            visible: false
        }        
    }

    componentDidMount() {
        this.search();
    }

    search = () => {

        this.setState ({ loading: true });

        let searchArgs = {
            keyword: this.state.keyword,
            orderBy: this.state.sorted,
            pagination: {
                pageNum: this.state.pagination.current,
                pageSize: this.state.pagination.pageSize
            }
        }

        settingsService.search(searchArgs)
            .then(data => {
                const pager = { ...this.state.pagination };
                pager.total = data.totalRecords;

                this.setState ({
                    items: data.settings,
                    pagination: pager
                });
            })
            .catch(err => console.log(err))
            .finally(() => {                
                this.setState ({ loading: false });
            })
    }
    
    handleTableChange = (paging, filters, sorter) => {
        let sorted = { ...this.state.sorted };
        const pager = { ...this.state.pagination };
        if(pager.pageSize !== paging.pageSize) {
            pager.pageSize = paging.pageSize;
            pager.current = 1;
        }
        else {
            pager.current = paging.current;
        }

        if(sorter.field)
            sorted = { id: sorter.field, desc: sorter.order === "descend" }

        this.setState({
            pagination: pager,
            sorted
        }, this.search);        
    };

    handleModal = (o) => {
        this.setState({
            selected: o,
            visible: true
        });
    }

    handleSettingUpdate = (o) => {
        // if updating
        if(this.state.selected && this.state.selected.id)
            o.id = this.state.selected.id;

        settingsService.post(o)
            .then(data => {
                const pager = { ...this.state.pagination };
                pager.total = data.totalRecords;

                this.setState ({
                    pagination: pager,
                    visible: false
                }, this.search);
            })
            .catch(err => console.log(err))
            .finally(() => {                
                this.setState ({ loading: false });
            })
    }



    render() {
        return (
            <Card
                title="System Settings"
                extra={ <Button type="primary" icon={<PlusOutlined />} color="btn btn-light" onClick={() => this.handleModal(null)} title="Add Setting">Add Setting</Button> }
            >

                <div>
                    
                    <Table
                        dataSource={this.state.items}
                        rowKey={x => x.id}
                        pagination={this.state.pagination}
                        loading={this.state.loading}
                        onChange={this.handleTableChange}  
                        size="middle"
                        className="clear-both pointer"
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: e => {
                                    this.handleModal(record)
                                }
                            }                                
                        }}
                        columns={[
                            { title: 'Name', dataIndex: 'name', sorter: true },
                            { 
                                title: 'Value', dataIndex: 'value', sorter: false,
                                render: (text,record) => (
                                    <Text style={{ width: 300 }} ellipsis={{ tooltip: text }}>{text}</Text>                                    
                                )
                            },
                            {
                                title: 'Type', dataIndex: 'isHost',
                                render: (text,record) => (
                                    <span>
                                        { record.isHost && <Tag color="blue">System</Tag> }
                                        { !record.isHost && <Tag color="purple">App</Tag> }
                                    </span>                                    
                                )
                            },
                            {
                                title: 'Privacy',
                                render: (text,record) => (
                                    <span>
                                        { record.isPublic && <Tag color="red">Public</Tag> }
                                        { !record.isPublic && <Tag color="green">Private</Tag> }
                                    </span>                                    
                                )
                            }
                        ]}
                    />
                </div>

                <SettingModal 
                    visible={this.state.visible}
                    onCreate={this.handleSettingUpdate}
                    onCancel={() => { this.setState({ visible: false}) }}
                    existing={this.state.selected} />

            </Card>
        );   
    }
}

SettingsList.contextType = SystemContext;
export default SettingsList;