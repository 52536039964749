import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { credentialService } from "services/CredentialService.js";
import { PlusOutlined, GlobalOutlined, MailOutlined } from "@ant-design/icons";
import { Table, Button, Card, Input, Row, Col, Select, Popconfirm } from "antd";
import moment from "moment";
import { notify } from "util/Notify";

const { Search } = Input;

export default function AgencyList() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [runSearch, setSearch] = useState(0);
    const [list, setList] = useState([]);
    const [keyword, setKeyword] = useState("");
    const [status, setStatus] = useState("active");
    const [sorted, setSorted] = useState({ id: "name", desc: false });
    const [pagination, setPagination] = useState({ current: 1, pageSize: 20, total: 0, position: ["topRight", "bottomRight"], showSizeChanger: true });

    useEffect(() => {
        setLoading(true);

        var args = {
            keyword,
            extra: status,
            orderBy: sorted,
            pagination: {
                pageNum: pagination.current,
                pageSize: pagination.pageSize,
            },
        };

        // get credentials
        credentialService
            .searchAgencies(args)
            .then((data) => {
                setList(data.items);
                const pager = { ...pagination };
                pager.total = data.totalRecords;
                setPagination(pager);
            })
            .catch((err) => console.log(err))
            .finally(() => setLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [runSearch]);

    const handleTableChange = (paging, filters, sorter) => {
        let s = { ...sorted };
        const pager = { ...pagination };
        if (pager.pageSize !== paging.pageSize) {
            pager.pageSize = paging.pageSize;
            pager.current = 1;
        } else {
            pager.current = paging.current;
        }

        if (sorter.field) s = { id: sorter.field, desc: sorter.order === "descend" };

        setSorted(s);
        setPagination(pager);
        setSearch(runSearch + 1);
    };

    const handleTypeChange = (s) => {
        const pager = { ...pagination };
        pager.current = 1;
        setPagination(pager);
        setStatus(s);
        setSearch(runSearch + 1);
    };

    const handleEmail = (id) => {
        credentialService
            .emailAgencyReviewers(id)
            .then((data) => {
                notify.success("Email sent");
            })
            .catch((err) => {
                notify.error(err);
            });
    };

    return (
        <Card
            title={"Manage Agencies"}
            extra={
                <Button type="primary" icon={<PlusOutlined />} color="btn btn-light" onClick={() => navigate(`/manage/credentials/agencies/agency`)} title="Add Agency">
                    Add Agency
                </Button>
            }
        >
            <div>
                <Row>
                    <Col sm={24} md={16} className="text-right">
                        <Select placeholder="Select Status" value={status} onChange={handleTypeChange} className="mr-2 w-40">
                            <Select.Option value="">Any Status</Select.Option>
                            <Select.Option value="active">Active</Select.Option>
                            <Select.Option value="implicit">Implicit</Select.Option>
                            <Select.Option value="disabled">Disabled</Select.Option>
                        </Select>
                    </Col>
                    <Col sm={24} md={8} className="text-right">
                        <Search
                            id="keyword"
                            placeholder="search"
                            onSearch={() => setSearch(runSearch + 1)}
                            enterButton
                            className="w-100"
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                            loading={loading}
                        />
                    </Col>
                </Row>
                <div className="w-60 mb-3 text-right float-right"></div>

                <Table
                    dataSource={list}
                    rowKey={(x) => x.id}
                    pagination={pagination}
                    loading={loading}
                    size="small"
                    className="clear-both"
                    onChange={handleTableChange}
                    columns={[
                        {
                            title: "Name",
                            dataIndex: "name",
                            sorter: true,
                            render: (text, record) => <Link to={`/manage/credentials/agencies/agency/${record.id}`}>{record.name}</Link>,
                        },
                        { title: "Abbv", dataIndex: "abbreviation", sorter: true },
                        { title: "Status", dataIndex: "status" },
                        {
                            title: "Web",
                            dataIndex: "websiteUrl",
                            align: "center",
                            render: (text, record) =>
                                record.websiteUrl && (
                                    <a href={record.websiteUrl} target="_blank">
                                        <GlobalOutlined />
                                    </a>
                                ),
                        },
                        {
                            title: "Created",
                            dataIndex: "createdOn",
                            sorter: true,
                            render: (text, record) => <span>{moment(record.createdOn).format("LL")}</span>,
                        },
                        {
                            title: "Updated",
                            dataIndex: "updatedOn",
                            sorter: true,
                            render: (text, record) => <span>{moment(record.updatedOn).format("LL")}</span>,
                        },
                        {
                            title: "Pending",
                            dataIndex: "totalPending",
                            align: "center",
                            sorter: true,
                            render: (text, record) => <span>{record.totalPending}</span>,
                        },
                        {
                            title: "",
                            align: "center",
                            render: (text, record) =>
                                record.totalPending > 0 && (
                                    <Popconfirm title="Are you sure you want to email this agency?" onConfirm={() => handleEmail(record.id)} okText="Yes" cancelText="No">
                                        <Button type="secondary" title="Email Agency Reviewers">
                                            <MailOutlined />
                                        </Button>
                                    </Popconfirm>
                                ),
                        },
                    ]}
                />
            </div>
        </Card>
    );
}
