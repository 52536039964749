import React from "react";
import { Route, Routes } from "react-router-dom";
import Layout from "components/MasterLayout";
import LeadList from "./leadList";
import Lead from "./lead";

export default function Leads(props) {
    return (
        <Layout>
            <Routes>
                <Route index element={<LeadList />} />
                <Route path="lead/:id?" element={<Lead />} />
                <Route path="*" element={<LeadList />} />
            </Routes>
        </Layout>
    );
}
